import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { ArrowLeft, Disc, Info } from "react-feather";
import { BsBarChart, BsChevronLeft, BsHouse } from "react-icons/bs";
import { CloseButton } from 'react-bootstrap';
import {Modal,ModalFooter} from 'reactstrap';
import { withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import { CDN_URL } from "../processENV";

const AssessResult = (props) => {
  const { navigation } = props;
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [selfc, setSelfC] = useState(false);
  const [resultdata, setResultData] = useState({});

  const openSheet = () => {
    setSheetOpen(true);
  };

  const closeSheet = () => {
    setSheetOpen(false);
  };
  useEffect(()=>{
    console.log(props)
    const data=props.location?.state?.data?.data?.result
    setResultData(data)
  },[props])
  const downloadfile=(filename,fileurl)=>{
    saveAs(fileurl,filename)
  }
  const cleanHTML = (html) => {
    if (!html) {
      return ''; 
    }
      const modifiedHtml = html.replace('<ol>', '<ol class="custom-list">');
      const cleanedHtml = modifiedHtml.replace(/<p><br><\/p>/g, '');
      return cleanedHtml;
  };
  const calculateBackgroundColor=(index, length)=>{
    const midIndex = Math.floor(length / 2);
    if (index === 0) {
      return '#d47a42';
    } else if (index === length - 1) {
      return '#74e86f';
    } else if (index === midIndex) {
      return '#1d4c91';
    } else if (index < midIndex) {
        // Interpolation between orange and blue for the first half of the array
        const ratio = index / midIndex;
        const red = Math.floor((1 - ratio) * 200); 
        const green = Math.floor(ratio * 150); 
        return `rgb(${red}, 50, ${green})`; 
      } else {
        // Interpolation between blue and green for the second half of the array
        const ratio = (index - midIndex) / (length - midIndex - 1);
        const blue = Math.floor((1 - ratio) * 200); 
        const green = Math.floor(ratio * 150); 
        return `rgb(50, ${blue}, ${green})`; 
      }
  }

  return (
    <div className="topcontainer_ col-md-12 mx-auto  m-0 p-0">
      <Modal isOpen={selfc} className='position-relative' centered size='md'>
          <CloseButton onClick={()=>setSelfC(false)} style={{right:15,top:15}} className='position-absolute' />
            <div className='p-5 '>
              <h5 className='text_p_ mt-md-4 font-weight-bold'>Unfortunately, it seems that the self-care courses are not currently available on our web app. We apologize for the inconvenience.</h5>
              <h5 className='text_p_ mt-3 font-weight-bold'>To access these courses, please download our mobile app from the App Store or Google Play.</h5>
              <h5 className='text_p_ mt-3 font-weight-bold'>Thank you for your patience!</h5>
            </div>
            <ModalFooter>
            <div className="mx-auto">
                <div className="text-center">
                <img
                  src={`${CDN_URL}/Others/qr-code.png`}
                  className="img-fluid text-center mx-auto"
                />
                </div>
              <div className='d-flex flex-row my-4 mx-auto justify-content-center'>
                <img onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.careme')} style={{height:'50px',width:'140px'}} src={`${CDN_URL}/Websiteicons/playstore.png`} className='img-fluid m-2'/>
                <img onClick={()=>window.open('https://apps.apple.com/GH/app/id1553777469?l=en')} style={{height:'50px',width:'140px'}} src={`${CDN_URL}/Websiteicons/appstore.png`} className='img-fluid m-2' />
              </div>
              </div>
            </ModalFooter>
        </Modal>
      <div className="col-md-8 mx-auto px-0 py-0 py-md-5">
       
        <div className="d-flex justify-content-between px-md-5 pt-md-0 pt-3 px-3 mt-md-3">
        <button
          onClick={()=>props.history.push('/workbook')}
            className="btn bg-white"
            style={{ borderColor: "#215352",borderWidth:'1px', color: "#215352" }}
          >
            <BsChevronLeft size={20} /> back to results
        </button>
        <button
          onClick={()=>props.history.push('/home')}
            className="btn bg-white"
            style={{ borderColor: "#215352",borderWidth:'1px', color: "#215352" }}
          >
            <BsHouse /> Go to dashboard
        </button>
        </div>
        <div className="col-md-8 mb-4 d-none d-md-block  text-center mx-auto">
        <img
                src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                className="img-fluid"
                style={{ maxHeight: "100px" }}
              />
        <div className=" mx-auto text-center h2 header_font_  _text_color " style={{opacity:.8}}>
        An in-depth explanation of your assessment - {resultdata?.title}
        </div>
       {(localStorage._nm&&!localStorage._patientKey)? <img src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} className='img-fluid' style={{maxHeight:'100px'}} />
        :null}
        </div>
    
          
        < Modal isOpen={isSheetOpen} className='position-relative m-0 p-0 border-none' centered size='md'
        >
            <div className="modal-content m-0 p-0 border-none">
              <div className="modal-header">
                <h5 className="modal-title header_font_ _text_color">
                 Understand the science behind your score
                 </h5>
                <button type="button" className="close" onClick={closeSheet}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body m-0">
                <div
                  style={{ opacity: 0.7 }}
                  className="text_p_ font-weight-light px-2"
                  dangerouslySetInnerHTML={{
                    __html: cleanHTML(resultdata?.score_header),
                  }}
                />
                <div
                  className="d-flex my-3 pt-3 px-2 rounded"
                  style={{ backgroundColor: "rgba(0,0,0,4%)" }}
                >
                    {resultdata?.result_range?.map((level, index) => (
                        <div className="flex-fill" key={index}>
                            <div className="progress" style={{ height: "20px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            <div className="progress-bar" role="progressbar"></div>
                            </div>
                            <p className="text-center py-0 mt-n3 font-weight-light text_p_">{level}</p>
                        </div>
                        ))}
                </div>
              </div>
              <div className="px-5">
                <div className="">
                  <div className="flex-fill d-flex">
                    <div
                      className="progress"
                      style={{ height: "18px", width: "10%" }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%", backgroundColor: "#f5a97a" }}
                      ></div>
                    </div>
                    <span className="text-dark px-2 header_font_">
                      {resultdata?.score_metrics?.low_range}
                    </span>
                  </div>
                  <p className="mt-n3 text_p_ font-weight-light">
                  <div
                  style={{ opacity: 0.7 }}
                  className="text_p_ font-weight-light px-2"
                  dangerouslySetInnerHTML={{
                    __html: cleanHTML(resultdata?.score_metrics_definitions?.low_range),
                  }}
                />
                  </p>
                </div>

                <div className="pt-3">
                  <div className="">
                    <div className="flex-fill d-flex">
                      <div
                        className="progress"
                        style={{ height: "18px", width: "10%" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "100%", backgroundColor: "#74e86f" }}
                        ></div>
                      </div>
                      <span className="text-dark px-2 header_font_">
                        {resultdata?.score_metrics?.moderate_range}
                      </span>
                    </div>
                    <p className="mt-n3 text_p_ font-weight-light">
                    <div
                    style={{ opacity: 0.7 }}
                    className="text_p_ font-weight-light px-2"
                    dangerouslySetInnerHTML={{
                      __html: cleanHTML(resultdata?.score_metrics_definitions?.moderate_range),
                    }}
                  />
                    </p>
                  </div>
                </div>

                <div className="pt-3">
                  <div className="">
                    <div className="flex-fill d-flex">
                      <div
                        className="progress"
                        style={{ height: "18px", width: "10%" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "100%", backgroundColor: "#698ec9" }}
                        ></div>
                      </div>
                      <span className="text-dark px-2 header_font_">
                        {resultdata?.score_metrics?.high_range}
                      </span>
                    </div>
                    <p className="mt-n3 text_p_ font-weight-light">
                        <div
                    style={{ opacity: 0.7 }}
                    className="text_p_ font-weight-light px-2"
                    dangerouslySetInnerHTML={{
                      __html: cleanHTML(resultdata?.score_metrics_definitions?.high_range),
                    }}
                  />
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: "#215352",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  onClick={closeSheet}
                >
                  Got it
                </button>
              </div>
            </div>
        </Modal>

        <div className="col-md-8 mx-auto py-md-2 shadow rounded border bg-white">
          <div className="resultContainer">
            <div className="d-flex justify-content-between px-2 py-1">
              <span style={{textDecorationLine:'underline'}} className="font-weight-light h3 py-3 header_font_ _text_color">Latest Score</span>
              {/* <span onClick={openSheet} className="text-primary">
                <Info /> Understand the science
              </span> */}
            </div>
            <div className="boxcontainer p-3 mt-2">
              <div className="scorediagram d-flex justify-content-center align-items-center pt-4">
              <div className="d-flex col-md-12 p-0">
                {resultdata?.result_range?.map((level, index) => (
                  <div className="flex-fill mx-1 position-relative" style={{ width: '80px' }} key={index}>
                    {resultdata?.progress_status === level && ( // Render the box only if progress status matches level
                      <React.Fragment>
                        <div className="score-box position-absolute d-flex justify-content-center align-items-center" style={{ top: "-50px", left: "50%", transform: "translateX(-50%)", backgroundColor: resultdata?.progress_status === level ? calculateBackgroundColor(index, resultdata?.result_range.length) : '#f2f2f2', border: ".2px solid #ccc", height: '30px', width: '35px' }}>
                          <span className="text-white">{resultdata.assessment_score}</span>
                        </div>
                        <div className="vertical-line position-absolute" style={{ top: "-20px", left: "50%", transform: "translateX(-50%)", width: "2px", height: "20px", backgroundColor: resultdata?.progress_status === level ? calculateBackgroundColor(index, resultdata?.result_range.length) : '#f2f2f2' }}></div>
                      </React.Fragment>
                    )}
                    <div className="progress" style={{ height: "20px", backgroundColor: resultdata?.progress_status === level ? calculateBackgroundColor(index, resultdata?.result_range.length) : '#f2f2f2' }}>
                      <div className="progress-bar mx-1" role="progressbar"></div>
                    </div>
                    <p className="text-center py-0 mt-n3 font-weight-light text_p_">{level}</p>
                  </div>
                ))}
              </div>
              </div>
            </div>
            <div className="scrollcontainer">
              <div className="scrollcontainer_sub">
                <div
                style={{opacity:.7}}
                  dangerouslySetInnerHTML={{
                    __html:cleanHTML(resultdata?.content),
                  }}
                />
                 {resultdata?.course?.length>0?<div>
                  <h5 style={{opacity:.8}} className="mt-3 mt-md-5 header_font_ _text_color text-left">
                    Recommended Courses
                  </h5>
                  {resultdata?.course?.map((course, index) => {
                    return (
                      <div style={{opacity:.7}} key={index} className="d-flex ml-2 mt-3">
                        <div className="mr-2">
                          <Disc color="#706d6d" size={16} />
                        </div>
                        <div onClick={()=>setSelfC(true)} style={{cursor:'pointer'}} className="d-flex">
                          <p className="mb-0">{course?.recommended_text}</p>
                          <p className="mb-0 pl-3" style={{textDecorationLine:'underline',color:'#0e6ac7'}}>
                            {course?.title}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>:null}
                {resultdata?.pushups_title? <div>
                  <h5 style={{opacity:.8}} className="mt-3 mt-md-5 header_font_ _text_color text-left">
                  Recommended Daily Pushup's
                  </h5>
                      <div onClick={()=>setSelfC(true)} style={{opacity:.7,textDecorationLine:'underline',color:'#0e6ac7'}} className="d-flex ml-2 mt-3">
                        <div className="mr-2">
                          <Disc color="#706d6d" size={16} />
                        </div>
                        <div className="d-flex">
                          <p className="mb-0">{resultdata?.pushups_title}</p>
                        </div>
                        </div>
                </div>:null}
                {resultdata?.exercise_data?.length>0? <div>
                  <h5 style={{opacity:.8}} className="mt-3 mt-md-5 header_font_ _text_color text-left">
                    Recommended Exercise's
                  </h5>
                  {resultdata?.exercise_data?.map((course, index) => {
                    return (
                      <div style={{opacity:.7}} key={index} className="d-flex ml-2 mt-3">
                        <div className="mr-2">
                          <Disc color="#706d6d" size={16} />
                        </div>
                        <div onClick={()=>downloadfile(course.exe_name,course.document)} style={{cursor:'pointer'}} className="d-flex">
                          <p className="mb-0 pl-3" style={{textDecorationLine:'underline',color:'#0e6ac7'}}>
                            {course?.exe_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>:null}
              </div>
            </div>
          </div>
          {/* <h4 className="mt-3 mt-md-5 header_font_ _text_color text-left">
            Tracks
          </h4>
          <div
            class="mx-auto mt-md-3 mt-2  rounded"
            style={{ backgroundColor: "#dbd5ce" }}
          >
            <div class="rounded-box border rounded p-3">
              <p class="text-center mx-auto py-1 h1 ">📈</p>
              <div
                style={{ opacity: 0.8, fontSize: "16px" }}
                class="text_p_  font-weight-bold"
              >
                Your record has less than two records,
                Low is two to unlock the full potential of the chart
                We encourage you to register your well-being at least once
                We encourage Record your well-being on several occasions
                By doing so, various factors will shape your journey
                Influence and value patterns and trends
                can be detected.
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AssessResult) ;
