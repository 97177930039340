import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import LeftContent from './LeftContent';
import Store from '../../../Store';
import { RescheduleAction } from '../../../Actions/RescheduleAction';
import SelectDate from './SelectDate';
import SelectTime from './SelectTime';
import Reschedule from './Reschedule';
import Loader from '../Loader';
import { withRouter } from 'react-router-dom';

class RIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rescheduleStep:1,
            rescheduleCurrentStep:1,
            date:'',
            time:'',
            data:'',
            loader:true
        };
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.rescheduleData !== this.props.rescheduleData) {
    //         this.setState({
    //             rescheduleStep: this.props.rescheduleData.data?.rescheduleStep,
    //             date: this.props.rescheduleData.data?.date,
    //             time: this.props.rescheduleData.data?.time
    //         })
    //     }
    //   }
    componentDidMount(){
        let item =this.props.rescheduleData.data?.prodId
        this.setState({
            data:item
        })
        setTimeout(()=>{
            this.setState({loader:false})
        },2000)
        console.log(this.props)
    }
    navigateComponent = (id) =>{
        this.setState({rescheduleStep:id})
        Store.dispatch(RescheduleAction({
            rescheduleStep:id,
            rescheduleCurrentStep:id,
            // currentStep:localStorage.getItem('_currentStep') !==null || localStorage.getItem('_currentStep') !== undefined  ? localStorage.getItem('_currentStep') : 0,
            // date: this.props.rescheduleData.data?.date,
            // time: this.props.rescheduleData.data?.time,
        }));
    }


    render() {
        const { rescheduleStep,data,loader } = this.state;
        console.log(rescheduleStep)
        return (
            <>
            {loader && <Loader />}
            {
                <div className={"login-main"}>
                    <Row className='m-0' >
                        <Col>
                            {rescheduleStep === 0 && <Reschedule navigateComponent={this.navigateComponent}/>}
                            {rescheduleStep === 1 && <SelectDate data={data && data}/>}
                            {rescheduleStep === 2 && <SelectTime data={data && data}/>}
                        </Col>
                        
                        {/* <Col md={4} className="col-sm-push-1 order-lg-1   px-0">
                            <LeftContent currentStep={this.props.rescheduleData.data?.rescheduleCurrentStep} step={rescheduleStep} navigateComponent={this.navigateComponent} />
                        </Col> */}

                    </Row>
                </div>
                }
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        rescheduleData: state.RescheduleData
    }
}

export default withRouter(connect(mapStateToProps, null)(RIndex));
