import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { APP_URL, REACT_APP_API_URL } from "../../processENV";
import Loader from "../Loader";

const AuthUser = (props) => {
  const { id } = useParams();

  useEffect(() => {
    const url = window.location.pathname;
    var data = { patient_n_key: "ORG-PAT-" + url.split("/")[1] };
    var jwt_token = url.split("/")[2];
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          localStorage.setItem("status", "completed");
          localStorage.setItem("_patientKey", "ORG-PAT-" + url.split("/")[1]);
          window.location.assign(APP_URL + "/home");
        } else {
          window.location.assign(APP_URL + "/home");
        }
      });
  }, []);

  return (
    <div style={{ margin: "150px auto" }}>
      <Loader />
    </div>
  );
};

export default AuthUser;
