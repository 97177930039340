import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { user } from "../../../assets/images";
import { Link } from "react-router-dom";
import { REACT_APP_API_URL } from "../../processENV";
import Loader from "../Loader";
import { withTranslation } from "react-i18next";

class HomeProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeTab: "1",
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      maritalStatus: "",
      age: "",
      error: "",
      loading: false,
      mainUser: [],
      familyUser: [],
    };
  }

  verticaltoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    this.setState({ loading: true });

    fetch(`${REACT_APP_API_URL}/total_users_list/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("user details", res);
        if (res.main_user) {
          this.setState({
            loading: false,
            mainUser: res.main_user,
            familyUser: res.family_user,
            firstName: res.main_user[0].first_name,
            lastName: res.main_user[0].last_name,
            dob: res.main_user[0].date_of_birth,
            gender: res.main_user[0].gender,
            maritalStatus: res.main_user[0].marital_status,
            age: res.main_user[0].age,
          });
        } else {
          this.setState({
            loading: false,
            error: "Some error has occured please try again",
          });
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginData !== this.props.loginData) {
      this.setState({
        step: this.props.loginData.data?.step,
        mobileNumber: this.props.loginData.data?.mobileNumber,
        countryCode: this.props.loginData.data?.countryCode,
      });
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  profileInputHandler = (e) => {
    let id = e.target.name;
    this.setState({
      [id]: e.target.value,
    });
  };

  handleDateChange = (ddd) => {
    if (ddd === null || ddd === "") {
      this.setState({
        dob: null,
      });
    } else {
      let date = new Date(ddd);

      let dd =
        date.getDate.toString().length === 1
          ? "0" + date.getDate()
          : date.getDate();
      let mm =
        date.getMonth().toString().length === 1
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let yy =
        date.getFullYear().toString().length === 1
          ? "0" + date.getFullYear()
          : date.getFullYear();

      let fromDate = yy + "-" + mm + "-" + dd;
      this.setState({
        dob: fromDate,
      });
    }
  };

  routeToFamily = (item) => {
    localStorage.setItem("_familyUserDetails", JSON.stringify(item));
    window.location.replace("/familyprofile");
  };

  render() {
    let { mainUser, loading } = this.state;

    return (
      <>
        <div className={"mainsec homeprofile"}>
          <Container>
            <Row className="profilecard">
              {loading && <Loader />}
              <Col md={6} xs={12} className="mb-2 px-0 mx-auto">
                {loading && <Loader />}
                <Card>
                  <CardBody className="col-md-10 col-12  mx-auto profilesec">
                    <Col md={12} xs={12} className="mb-2 mx-auto documentSec">
                      <Row>
                        {mainUser &&
                          mainUser.map((item) => {
                            return (
                              <Col
                                md="6"
                                className="text-center profileIcon userIcon myProfile"
                              >
                                <Link to="patientprofile">
                                  <img src={user} alt="" />
                                  <span>{item.full_name}</span>
                                  <span>
                                    {item.age}yr old {item.gender}
                                  </span>
                                </Link>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(HomeProfile));
