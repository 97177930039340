import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Flag } from 'react-feather';
import Store from '../../../Store';
import { RescheduleAction } from '../../../Actions/RescheduleAction';
import Loader from '../Loader';
import { REACT_APP_API_URL } from '../../processENV';

class SelectTime extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            time: [],
            selectedTime:'',
        };
    }

    componentDidMount() {
        console.log(this.props)
        let data = {
            "employee_n_key":this.props.rescheduleData.data?.prodId?.doc_app_id,
            "rescheduled_date": this.props?.date,
            "appointment_n_key":this.props.rescheduleData.data?.prodId.appointment_n_key,
            "app_prod_grp_key":this.props?.rescheduleData?.data.prodId.app_prod_grp_key
        }

        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/app_rescheduled_time/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("re appoint time",res.data)
                if(res.status === "success"){
                    this.setState({loading:false, time:res.data})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }

    selectTime = (value) =>{
        this.setState({selectedTime:value},()=> this.rescheduleAppointment())
       
        // Store.dispatch(RescheduleAction({
        //     getCareStep:4,
        //     getCareCurrentStep:4,
        //     date: this.props.rescheduleData.data?.date,
        //     time: value,
        //     language: this.props.rescheduleData.data?.language
        // }));
    }
    rescheduleAppointment = () =>{

        let endTimeCal = parseInt(this.state.selectedTime.split(':')[0]) + 1 
        let endTime = endTimeCal.toString() +  ":00"

        let data = {
            "patient_n_key":  localStorage.getItem('_patientKey'),
            "appointment_n_key":this.props?.prod,
            "appointment_date": this.props?.date,
            "appointment_time": this.state.selectedTime,
            "appointment_end_time":endTime
        }
        console.log("res", data)
        fetch(`${REACT_APP_API_URL}/app_rescheduled_appoint/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                console.log("rescheduleAppointment", res)
                if (res.status === "success") {
                    this.setState({ loading: false })
                    window.location.replace('/home')
        
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))
    }

    render() {
        let { time, loading, selectedTime } = this.state;
        return (
            <>
                <div className="col-md-8 mx-auto bg-none mt-5 pt-5">
                    {loading && <Loader />}
                    <div className="d-flex mx-auto mt-5">
                        <Col className="mx-auto text-center">
                            <p style={{fontSize:'26px'}} className='h4 header_font_ font-weight-bold'>You can schedule your appointment time now based on your convenience and check your meeting schedule on your home screen. </p>
                            { this.props.rescheduleData.data?.date && <p style={{fontSize:'16px'}} className='h4 header_font_ font-weight-bold'>{ this.props.rescheduleData.data?.date}</p>}
                            { selectedTime && <p style={{fontSize:'16px'}} className='h4 header_font_ font-weight-bold'>{ selectedTime}</p>}
                            <div className=" mt- 5 pt-5 col-md-6 mx-auto">
                                {time && time.map((item, i)=>{
                                    return <p style={{fontFamily:'Hanken Grotesk',fontSize:'20px'}} className={'p-3 border text_p_ shadow-sm text_medium_'} key={i} onClick={()=>this.selectTime(item)}>{item}</p>
                                })}
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        rescheduleData: state.RescheduleData
    }
}

export default connect(mapStateToProps, null)(SelectTime);
