import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { ArrowRight, Check, Phone } from "react-feather";
import { REACT_APP_API_URL, REACT_APP_BASEURL } from "../../processENV";
import Store from "../../../Store";
import { LoginStateAction } from "../../../Actions/LoginAction";
import Loader from "../Loader";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Modal, ModalBody } from "reactstrap";
import { CloseButton } from "react-bootstrap";
import { EmailIcon, WhatsappIcon } from "react-share";
import { BsEnvelopeOpen } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dialdata } from "../../../Helpers/dialdata";

class MobileNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: this.props.loginData.data?.countryCode || "+91",
      mobileNumber: "",
      ccError: false,
      mobileError: false,
      loading: false,
      emailerror: false,
      notRegister: false,
      frommob_: false,
      notemployee_: false,
    };
  }

  componentDidMount() {
    if (
      this.props?.loginData?.data?.email &&
      this.props?.loginData?.data?.business
    ) {
      this.setState({ email: this.props?.loginData?.data?.email });
    }
    if (
      this.props?.loginData?.data?.mobileNumber &&
      this.props?.loginData?.data?.countryCode
    ) {
      this.setState({
        countryCode: this.props?.loginData?.data?.countryCode,
        mobileNumber: this.props?.loginData?.data?.mobileNumber,
      });
    }
  }

  onInputChange = (e) => {
    let item = e.target.name;
    let dc = this.state.countryCode ? this.state.countryCode : null;
    this.setState({ [item]: e.target.value });
    if (dc == "+91") {
      if (e.target.value.length === 10) {
        this.setState({ mobileError: false, ccError: false });
      }
    }
  };
  validemail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  onEmailChange = (e) => {
    if (this.validemail(e.target.value)) {
      this.setState({ email: e.target.value, emailerror: false });
    } else {
      this.setState({ emailerror: true });
    }
  };

  checkMob = async () => {
    let { countryCode, mobileNumber, mobileError } = this.state;
    let data = {
      phone_number: mobileNumber,
      dial_code: countryCode,
    };
    console.log(data);
    let headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    await axios
      .post(REACT_APP_BASEURL + "api/business/bu_mobile_login/", data, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "failed") {
          this.setState({ notemployee_: true });
        } else {
          this.setState({ notemployee_: false });
          fetch(`${REACT_APP_API_URL}/sendotp/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then(async (res) => {
              if (res.status === "success") {
                console.log("mobile number reponse", res);
                localStorage.setItem("_currentStep", 2);
                localStorage.removeItem("questiondata");
                localStorage.removeItem("currentAnswer");
                Store.dispatch(
                  LoginStateAction({
                    step: 1,
                    currentStep: 1,
                    mobileNumber: mobileNumber,
                    countryCode: countryCode,
                    detail: res,
                    language: this.props.loginData.data.language,
                    business: res.user_type == "Business" ? true : false,
                  })
                );
                this.setState({ loading: false });
              } else {
                this.setState({ loading: false });
                toast.error(res.data);
                console.log("mobile number error", res);
              }
            })
            .catch((err) => {
              this.setState({ loading: false });
              console.log("mobile number reponse error", err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendOtp = async (e) => {
    console.log(this.state.frommob_);
    if (this.props.loginData.data.business) {
      const { email } = this.state;
      if (this.validemail(email)) {
        if (e) {
          e.preventDefault();
        }
        let data = { email: email };
        let headers = {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        };
        await axios
          .post(REACT_APP_BASEURL + "api/business/sendotp/", data, { headers })
          .then((res) => {
            if (res.data.status === "success") {
              localStorage.setItem("_currentStep", 2);
              localStorage.removeItem("questiondata");
              localStorage.removeItem("currentAnswer");
              Store.dispatch(
                LoginStateAction({
                  step: 1,
                  currentStep: 1,
                  detail: res.data,
                  language: this.props.loginData.data.language,
                  email: email,
                  business: true,
                })
              );
              this.setState({ loading: false });
            } else if (
              res.data.status == "failed" &&
              res.data.data == "Not Business User"
            ) {
              this.setState({ notRegister: true, loading: false });
            } else {
              this.setState({ loading: false });
              toast.error(res.data);
              console.log("mobile number error", res.data);
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log("mobile number reponse error", err);
          });
      } else {
        this.setState({ emailerror: true });
      }
    } else if (this.state.frommob_) {
      e.preventDefault();
      this.checkMob();
    } else {
      let { countryCode, mobileNumber, mobileError } = this.state;
      let data = {
        phone_number: mobileNumber,
        dial_code: countryCode,
      };
      if (e) {
        e.preventDefault();
      }
      if (!countryCode) {
        this.setState({ ccError: true, mobileError: false });
      } else if (countryCode == "+91" && mobileNumber.length !== 10) {
        this.setState({ mobileError: true, ccError: false });
      } else {
        this.setState({ mobileError: false, ccError: false, loading: true });
        fetch(`${REACT_APP_API_URL}/sendotp/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then(async (res) => {
            if (res.status === "success") {
              console.log("mobile number reponse", res);
              localStorage.setItem("_currentStep", 2);
              localStorage.removeItem("questiondata");
              localStorage.removeItem("currentAnswer");
              Store.dispatch(
                LoginStateAction({
                  step: 1,
                  currentStep: 1,
                  mobileNumber: mobileNumber,
                  countryCode: countryCode,
                  detail: res,
                  language: this.props.loginData.data.language,
                  business: res.user_type == "Business" ? true : false,
                })
              );
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
              toast.error(res.data);
              console.log("mobile number error", res);
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log("mobile number reponse error", err);
          });
      }
    }
  };
  handleprevs = async () => {
    this.setState({ notRegister: false, frommob_: true });
    Store.dispatch(
      LoginStateAction({
        step: 0,
        currentStep: 0,
        business: false,
        language: this.props.loginData.data.language,
      })
    );
  };
  handleprev = async () => {
    this.setState({ notRegister: false });
    Store.dispatch(
      LoginStateAction({
        step: 0,
        currentStep: 0,
        business: false,
        language: this.props.loginData.data.language,
      })
    );
  };
  handleemail = async () => {
    this.setState({ notRegister: false, notemployee_: false, frommob_: false });
    Store.dispatch(
      LoginStateAction({
        step: 0,
        currentStep: 0,
        business: true,
        language: this.props.loginData.data.language,
      })
    );
  };
  openDialPad = () => {
    const phoneNumber = "+917829002002"; // Replace with the desired default number
    const url = `tel:${phoneNumber}`;
    window.open(url, "_blank");
  };

  openEmail = () => {
    const emailAddress = "example@example.com"; // Replace with the desired default email address
    const url = `mailto:${emailAddress}`;
    window.open(url, "_blank");
  };
  handleClickWhatsApp = () => {
    const url = `https://wa.me/?text=💌 "Dear HR, I found this fantastic mental health app that could really uplift our team's spirits and productivity. What about adding our organization to their list? It could be a game-changer!
      {\n}Thanks for your understanding and remember, your journey towards mental wellness is a marathon, not a sprint. One step at a time! 😊👣🌈'`;
    window.open(url);
    this.setState({ notRegister: false });
  };

  handleClickEmail = () => {
    const body = `💌 Dear HR, I found this fantastic mental health app that could really uplift our team's spirits and productivity. What about adding our organization to their list? It could be a game-changer!
      {\n}Thanks for your understanding and remember, your journey towards mental wellness is a marathon, not a sprint. One step at a time! 😊👣🌈'`;
    const encodedBody = encodeURIComponent(body);
    const url = `mailto:?body=${encodedBody}`;
    window.open(url);
    this.setState({ notRegister: false });
  };
  render() {
    let { mobileError, ccError, loading, notRegister } = this.state;
    let { t } = this.props;
    console.log(this.props.loginData);
    return (
      <>
        <ToastContainer />
        <div className="mobile-number-outer">
          <Modal
            size="xs"
            centered
            className="position-relative"
            isOpen={notRegister}
          >
            <div
              style={{ right: 10, top: 15, zIndex: 999 }}
              className="position-absolute"
            >
              <CloseButton
                onClick={() => {
                  this.setState({ notRegister: false });
                }}
              />
            </div>
            <ModalBody className="p-5">
              <p
                style={{ fontSize: "17px" }}
                className="text_p_ text-center mt-md-3"
              >
                Uh-oh! 😕 It seems your organization isn't part of our
                mindfulness family yet. But hey, no need to feel left out!
              </p>
              <p style={{ fontSize: "17px" }} className="text_p_ text-center">
                👤 You can always continue as an individual and start your
                wellness journey right away. All you need to do is tap on the
                button below!
              </p>
              <div className="mx-auto text-center my-5">
                <button
                  onClick={() => {
                    this.handleprev();
                  }}
                  style={{ backgroundColor: "#215352" }}
                  className="btn text-center text-white py-2 px-4 font-weight-bold"
                >
                  Login as individual
                </button>
              </div>
              <p
                style={{ fontSize: "17px" }}
                className="text_p_ text-left mt-md-4"
              >
                And if you believe your whole team could use a little more
                balance and positivity, why not become wellness ambassadors?
                Share this message with your HR department
              </p>
              <div className="mx-auto d-flex flex-row justify-content-center">
                <WhatsappIcon
                  style={{ cursor: "pointer" }}
                  onClick={this.handleClickWhatsApp}
                  size={28}
                  className="m-2  rounded-circle"
                />
                <EmailIcon
                  style={{ cursor: "pointer" }}
                  onClick={this.handleClickEmail}
                  size={28}
                  className="m-2 rounded-circle"
                />
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="xs"
            centered
            className="position-relative"
            isOpen={this.state.notemployee_}
          >
            <div
              style={{ right: 10, top: 15, zIndex: 999 }}
              className="position-absolute"
            >
              <CloseButton
                onClick={() => {
                  this.setState({ notemployee_: false });
                }}
              />
            </div>
            <ModalBody className="p-5">
              <p
                style={{ fontSize: "17px" }}
                className="text_p_ text-center mt-md-3"
              >
                ⚠️ Oops! The entered mobile number is not registered with our
                business organization. 😞
              </p>
              <p style={{ fontSize: "17px" }} className="text_p_ text-center">
                📧 Please log in using your organization email to access your
                account. 📩
              </p>
              <div className="mx-auto text-center my-5">
                <button
                  onClick={() => {
                    this.handleemail();
                  }}
                  style={{ backgroundColor: "#215352" }}
                  className="btn text-center text-white py-2 px-4 font-weight-bold"
                >
                  Login with email
                </button>
              </div>
              <p
                style={{ fontSize: "17px" }}
                className="text_p_ text-left mt-md-4"
              >
                If you need any assistance, feel free to contact our support
                team. 🤝
              </p>
              <div className="mx-auto d-flex flex-column justify-content-center">
                <p className="text-center" style={{ cursor: "pointer" }}>
                  <Phone
                    onClick={this.handleClickWhatsApp}
                    size={16}
                    className="m-2  rounded-circle"
                  />{" "}
                  +91 7829-002-002
                </p>
                <p className="text-center" style={{ cursor: "pointer" }}>
                  <BsEnvelopeOpen
                    style={{ cursor: "pointer" }}
                    onClick={this.handleClickEmail}
                    size={16}
                    className="m-2 rounded-circle"
                  />{" "}
                  care@careme.health
                </p>
              </div>
            </ModalBody>
          </Modal>
          {loading && <Loader />}
          <div className="d-flex">
            <Col md={8} className="mx-auto px-3">
              <div className="mobile-number-inner">
                <h2 className="header_font_ text-white">
                  {this.props?.loginData?.data?.business
                    ? `Enter Your Organisation email and we'll send you a verification code`
                    : `Enter Your Phone Number and we'll send you a verification code`}
                </h2>
                <form onSubmit={this.sendOtp}>
                  {this.props?.loginData?.data?.business ? (
                    <>
                      <Row className="mx-auto mt-5">
                        <Col md={12} className="mx-auto px-0 text-center">
                          <div className="d-inline input_Icon text-center">
                            {" "}
                            <input
                              type="text"
                              placeholder="example@mail.com"
                              name="mobileNumber"
                              className={`form-control col-sm-8 col-8 d-inline text_p_ _text_color bg-none ${
                                this.state.emailerror ? "is-invalid" : ""
                              }`}
                              defaultValue={this.state.email}
                              autoFocus
                              onChange={this.onEmailChange}
                            />
                            {!this.state.emailerror && this.state.email ? (
                              <span>
                                {" "}
                                <Check size={20} />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                        <div className="d-flex flex-column mx-auto">
                          {this.state.emailerror && (
                            <p className="error text-center text_p_ p-0 mt-3 mx-auto">
                              Enter a valid email
                            </p>
                          )}
                          {/* <p onClick={()=>{this.handleprevs()}} style={{textDecorationLine:'underline'}} className='mt-md-3 text_p_ text-white text-center mx-auto'> If you are already registered with us, you can log in using your mobile number.</p> */}
                        </div>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className="mx-auto mt-5">
                        <Col md={12} className="mx-auto px-0 ">
                          <select
                            name="countryCode"
                            className={`form-control text_p_ col-sm-3 col-3 pl-1 d-inline mr-2 ${
                              ccError ? "is-invalid" : ""
                            }`}
                            value={this.state.countryCode}
                            placeholder=""
                            onChange={this.onInputChange}
                          >
                            {dialdata &&
                              dialdata.map((item, i) => {
                                return (
                                  <option value={item.code}>
                                    {" "}
                                    {item.code}
                                  </option>
                                );
                              })}
                          </select>
                          <div className="d-inline input_Icon">
                            {" "}
                            <input
                              placeholder="Phone number"
                              type="number"
                              name="mobileNumber"
                              className={`form-control text_p_ col-sm-8 col-8 d-inline bg-none  ${
                                mobileError ? "is-invalid" : ""
                              }`}
                              value={this.state.mobileNumber}
                              autoFocus
                              onChange={this.onInputChange}
                            />
                            {this.state.mobileNumber.length === 10 ? (
                              <span>
                                {" "}
                                <Check size={20} />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>{" "}
                        </Col>
                      </Row>
                    </>
                  )}
                  {ccError && (
                    <p className="error">Country code is not valid</p>
                  )}
                  {mobileError && <p className="error">Enter Valid Number</p>}
                  <h6 className="header_font_ text-center">
                    We promise it will be safe with us
                  </h6>
                  <h5 className="header_font_ text-white text-center">
                    {" "}
                    By Clicking continue you agree to caremehealth's Terms of
                    service and privacy notice.
                  </h5>
                  <div className="text-center py-md-2 py-5">
                    <button
                      type="submit"
                      className="btn btn-primary mt-md-4 w-100"
                    >
                      Continue
                      <ArrowRight />{" "}
                    </button>
                  </div>
                </form>{" "}
              </div>
            </Col>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(MobileNumber));
