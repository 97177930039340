import React from "react";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { REACT_APP_API_URL } from "../../processENV";
import { useEffect } from "react";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
import { Download, ArrowRight } from "react-feather";

const Prescription = () => {
  const [documentList, setDocumentList] = useState([]);

  const getDocumentList = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/list_prescriptionapp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("document list", res);
        setDocumentList(res.data);
      })
      .catch((err) => console.log("alcohol list error", err));
  };
  const downld = async (document_attachment, document_name) => {
    saveAs(document_attachment, document_name);
  };
  useEffect(() => {
    getDocumentList();
  }, []);
  return (
    <div className="container">
      <div className="col-md-8 mx-auto my-5 pt-5">
        <ToastContainer />
        <h3
          style={{ textDecorationLine: "underline" }}
          className="header_font_ _text_color text-center mt-md-5 pt-md-3 mb-md-5"
        >
          Medication History Log
        </h3>

        {documentList.length !== 0 ? (
          <>
            {documentList.map((i, indx) => {
              return (
                <div
                  style={{ border: "1px solid #215352" }}
                  className="row shadow-sm col-md-7 m-2 mt-4 mx-auto d-flex bd-highlight position-relative rounded py-2"
                >
                  <div className=" p-2 flex-fill bd-highlight">
                    <p className="header_font_ h5 _text_color">
                      {" "}
                      Prescribed by : {i.doc_name}
                    </p>
                    <p className="header_font_ _text_color">
                      {" "}
                      Prescribed on :{" "}
                      {moment(i.created_on).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <div
                    style={{ right: 70 }}
                    className=" p-4 flex-fill bd-highlight position-absolute"
                  >
                    <Download
                      onClick={() =>
                        downld(i.document, `prescription${i.prescribe_id}`)
                      }
                      className="dow_icn pres_icn"
                      size={25}
                      color="#215352"
                    />
                  </div>
                  <div
                    style={{ right: 20 }}
                    className=" p-4 flex-fill bd-highlight position-absolute"
                  >
                    <a href={i.document} target="_blank">
                      <AiFillEye size={25} color="#215352" />{" "}
                    </a>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="row col-md-6 mx-auto my-auto mt-md-5">
            <div className="mt-md-5">
              <h4 className="text-center font-weight-bold h3">
                We Can’t wait to meet you!
              </h4>
              <p className="text-center  mt-4 h5">
                Schedule your 1st appointment to get connected to your CareMe
                Therapist.
              </p>
              <p className="text-center mt-5 h5">
                <span className="h5">Need help getting started?</span>
                <br />
                Reach out to <a href="">care@careme.health</a> and a team member
                will respond as soon as possible.
              </p>
            </div>
            <div className="text-center mx-auto mt-4">
              <button
                style={{
                  background: "#215352",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                className="btn btn-md btn-block "
                onClick={() => window.location.replace("offerings")}
              >
                Book your first session <ArrowRight />{" "}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Prescription;
