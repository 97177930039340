import React, { FC } from 'react';

interface CheckboxOption {
  value?: string|number;
  name: string;
  checked:boolean;
}

interface CheckboxSectionProps {
  sectionId: string;
  topLabel?:string;
  options: CheckboxOption[];
  handleChange: (index: number) => void;
}

const CheckboxSection: FC<CheckboxSectionProps> = ({ sectionId, options,topLabel,handleChange }) => {


  const handleToggleCheckbox = (index: number) => {
    const updatedItems = [...options];
    updatedItems[index].checked = !updatedItems[index].checked;
    handleChange(index);
  };
  return (
    <>
    <p style={{fontSize:'14px',opacity:.8}} className='text-gray-700 font-mono py-1'>{topLabel}</p>
    <div className='d-flex flex-column mr-4'>
      {options.map((option, optionIdx) => (
        <button
        key={optionIdx}
        onChange={() => handleToggleCheckbox(optionIdx)}
        className="font-weight-bold rounded d-flex btn-block mr-5 align-items-center flex-row _text_color m-1"
        style={{backgroundColor:'#fff',border:'1px solid #215352'}}
      >
        <label className="d-flex align-items-center pt-1">
          <input
            type="checkbox"
            checked={option.checked}
            onChange={() => handleToggleCheckbox(optionIdx)}
            className="form-checkbox mt-1 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span style={{fontSize:'12px'}} className="ml-2 text-xs pt-1">{option.name}</span>
        </label>
      </button>
      ))}
      </div>
      <div className='border-b-2 pt-3 border-gray-200' />
    </>
  );
};

export default CheckboxSection;
