import { THERAPY_JOURNEY_STATUS } from '../Actions/Constants';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  id:uuidv4(),
  data:{
    therapyStep:1,
    therapyCurrentStep:1,
    date:'',
    time:'',
    prodId:'',
    empId:'',
    indx:'',
  }
  }

  const mergeData = data =>{
    let newData = {
      id:data.id,
      data: {
        ...initialState.data,
        ...data.data
      }
    }
    return newData
  }
export function TherapyJourneyReducer(state = initialState, action) {
    switch (action.type) {
      case THERAPY_JOURNEY_STATUS:
        return  mergeData(action.payload);
      default:
        return state
    }
  }