import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeftCircle, Flag } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import Loader from '../../Loader';
import { REACT_APP_API_URL } from '../../../processENV';

class Confirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            time: [],
            
        };
    }
    updatePay=()=>{
       let data = {
          payment_status: "Completed",
          amount: this.props?.item.product_amount,
          payment_details: this.props?.item.payment_details,
          patient_n_key: localStorage.getItem('_patientKey'),
          app_prod_n_key: this.props?.item.app_prod_n_key,
          app_payment_n_key:this.props?.item.app_payment_n_key,
          token_check: "",
          uuid_token: null,
          appointment_n_key: this.props?.item.appointment_n_key
        }
        fetch(`${REACT_APP_API_URL}/cashfree_payment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
              console.log('selected service',res)
                if(res.status === "success"){
                    window.location.replace('/home')
                }else{
                  console.log('selected service',res.data)
                }
            })
        
    }

    appointDoctor = () =>{
        
        let data = {
                "patient_n_key":localStorage.getItem('_patientKey'),
                "appointment_date":this.props.scheduleData.data.date,
                "appointment_time":this.props.scheduleData.data.time,
                "product":this.props.scheduleData.data.data1.product||null,
                "app_payment_n_key":this.props.scheduleData.data.data1.app_payment_n_key,
                "doc_app_id":null,
                "app_prod_n_key":this.props.scheduleData.data.data1.app_prod_n_key,
                "mode_of_telehealth":this.props.scheduleData.data.mode||'Video',
                'app_prod_grp_key':this.props?.item?.app_prod_grp_key,
                "therapist_gender":this.props.scheduleData.data.gender ? this.props.scheduleData.data.gender  === "Any" ? 'any' :this.props.scheduleData.data.gender  : 'any',
                "preferred_language": this.props.scheduleData.data.language||'English',

             }

        
        fetch(`${REACT_APP_API_URL}/session_appointment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(async (res) => {
                console.log("session_appointment", res)
                this.setState({
                    appointment:res.data
                })
                if (res.status === "success") {
                    this.setState({transactionStatus:res.data.overall_status, loading:false},()=>{
                        setTimeout(()=>{window.location.replace('/home')},2000)
                    })
                        //    window.location.replace('/home')
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))
    }


    confirmAppointment=()=>{
            this.setState({loading:true})
        let key=this.props?.item.app_prod_n_key
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey'),
            "app_prod_n_key":this.props.scheduleData.data.problem.app_prod_n_key,
            "appointment_time":this.props.scheduleData.data.time,
            "mode_of_telehealth":this.props.scheduleData.data.mode,
            "appointment_date": this.props.scheduleData.data.date,
            "preferred_language": this.props.scheduleData.data.language,
            "therapist_gender":this.props.scheduleData.data.gender ? this.props.scheduleData.data.gender  === "Any" ? 'any' :this.props.scheduleData.data.gender  : 'any',
            "product": "Care Room",
            "tax": "0",
            "tax_amount": "0",
            "status": "Paid",
            "paid_amount":this.props?.item.amount ,
            "sale_price":this.props?.item.product_amount ,
            "app_payment_n_key": this.props?.item.app_payment_n_key,
            "product_amount":this.props?.item.product_amount,
            "total_session": this.props?.item.total_session,
            "discount":this.props?.item.discount,
            "discount_amount": this.props?.item.discount_amount,
             "patient2":this.props.scheduleData.data.pt_key ?this.props.scheduleData.data.pt_key:null,
             "addon":this.props.item.addon?this.props.item.addon:null,
             "app_prod_grp_key":this.props?.item?.app_prod_grp_key,
            "doc_app_id":this.props?.scheduleData?.data?.therapist?.employee_n_key,


        }
        console.log(data)
        fetch(`${REACT_APP_API_URL}/careteam_book_appointment/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log('selected service',res)
              if(res.status === "success"){
            //    this.updatePay()
            console.log('Success')
            window.location.replace('/home')
              }else{
                console.log('selected service',res.data)
              }
          })
    }

    goBack = () =>{
        const item = this.props?.item;
    const scheduleData = this.props?.scheduleData?.data;
    Store.dispatch(
      ScheduleAction({
        scheduleStep:
          item?.title === "Couple Therapy"
            ? 4
            : item?.title === "Psychiatric Consultation"
            ? 3
            : 5,
        scheduleCurrentStep:
          item?.title === "Couple Therapy"
            ? 4
            : item?.title === "Psychiatric Consultation"
            ? 3
            : 5,
        mode: scheduleData?.mode,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        gender: scheduleData?.gender,
        date: scheduleData?.date,
        therapist: scheduleData?.therapist,
        pt_key:scheduleData?.pt_key,
      })
    );
    }

    render() {
        const  {loading} = this.state;
        const scheduleData=this.props?.scheduleData?.data;
        return (
                <div className="col-md-8 mx-auto pt-5">
                            <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none ">
                        <ArrowLeftCircle color="#215352" size={25} />
                        </button>
                     {loading && <Loader />}
                    <div className="d-flex mx-auto mt-5 ">
                        <Col  className=" mx-auto text-center ">
                                <div className='col-md-5 mx-auto my-3 bordered bg-white shadow py-3 px-4'>
                                <h3 style={{fontSize:'20px',color:'#215352'}} className='h4 header_font_ font-weight-bold my-3'>
                            Confirm Your Appointment
                           </h3>
                                <div className='row d-flex flex-row justify-content-center mx-auto mt-4 pt-3'>
                                <p className='mr-auto ' >Appointment Date </p>
                                <p className='ml-auto text-left'>{scheduleData?.date}</p>
                                </div>
                                <div className='row d-flex flex-row justify-content-center mx-auto'>
                            <p className='mr-auto'>Appointment Time</p>
                            <p className='ml-auto'>{scheduleData?.time}</p>
                                    </div>
                                    <div className='row d-flex flex-row justify-content-center mx-auto'>
                            <p className='mr-auto'>Appointment Language</p>
                            <p className='ml-auto text-left'>{scheduleData?.language}</p>
                                        </div> 
                                        <div className='row d-flex flex-row justify-content-center mx-auto'>   
                            <p className='mr-auto'>Mode Of Appointment</p>
                            <p className='ml-auto text-left'>{scheduleData?.mode}</p>
                            </div>
                            <div className='mx-auto text-center my-4'>
                                <button onClick={()=>{
                                    if(this.props?._fol){
                                     this.appointDoctor()
                                     }
                                     else{
                                    this.confirmAppointment()
                                     }}
                                    } className='btn btn-block btn_Schdule'>Confirm</button>
                            </div>
                            </div>
                           
                         </Col>

                    </div>
                </div>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        scheduleData: state.ScheduleData
    }
}

export default connect(mapStateToProps, null)(Confirm);
