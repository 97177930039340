import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Col } from "reactstrap";
import Store from "../../../Store";
import { LoginStateAction } from "../../../Actions/LoginAction";
import { B_URL, REACT_APP_API_URL, REACT_APP_BASEURL } from "../../processENV";
import Loader from "../Loader";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const publicIp = require("react-public-ip");
const createHistory = require("history").createBrowserHistory;

export const history = createHistory();
class OtpValidation extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      otp: "",
      otpError: false,
      errorMessage: "",
      loading: false,
      sendNew: false,
    };
  }
  handleChange = (otp) => this.setState({ otp });

  resendOtp = () => {
    let data = this.props.loginData.data.business
      ? { email: this.props.loginData.data.email }
      : {
          phone_number: this.props.loginData.data.mobileNumber,
          dial_code: this.props.loginData.data.countryCode,
        };
    this.setState({ loading: true, otp: "", otpError: false });
    fetch(
      this.props.loginData.data.business
        ? REACT_APP_BASEURL + "api/business/sendotp/"
        : `${REACT_APP_API_URL}/sendotp/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === "success") {
          console.log("OTP resend  reponse", res);
          localStorage.setItem("_currentStep", 2);
          Store.dispatch(
            LoginStateAction({
              step: 1,
              currentStep: 1,
              mobileNumber: this.props.loginData.data.mobileNumber,
              countryCode: this.props.loginData.data.countryCode,
              detail: res,
              language: this.props.loginData.data.language,
              email: this.props.loginData.data.email,
              business: this.props.loginData.data.business,
            })
          );
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          console.log("OTP resend error", res);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("OTP resend  reponse error", err);
      });
  };

  async loadEmp() {
    this.setState({ loading: true });
    let data = {
      email: this.props.loginData.data.email,
      typedotp: this.state.otp,
      ip_address: (await publicIp.v4()) || "",
      phone_number: this.props.loginData.data?.mobileNumber,
      dial_code: this.props.loginData.data?.countryCode,
    };
    console.log(REACT_APP_BASEURL + "api/business/login_otp/");
    fetch(`${REACT_APP_BASEURL}api/business/login_otp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        if (response.status == "NewBusiness") {
          this.setState({ loading: false });
          let pendingArray = [
            "first_name",
            "last_name",
            "date_of_birth",
            "marital_status",
            "gender",
            "dial_code",
            "phone_number",
          ];
          this.props.history.push("/pendingQues", {
            pendingArray,
            email: this.props.loginData.data.email,
          });
        } else if (response.status == "success") {
          localStorage.setItem("_userData", JSON.stringify(response.data));
          localStorage.setItem(
            "_initial",
            JSON.stringify(response.initial_check)
          );
          localStorage.setItem("_patientStatus", response.status);
          localStorage.setItem("_patientKey", response.data.patient_n_key);
          localStorage.setItem(
            "_appointmentKey",
            response.data.appointment_n_key
          );
          localStorage.setItem("_firstName", response.data.first_name);
          localStorage.setItem("_fullName", response.data.full_name);
          localStorage.setItem(
            "_mobileNumber",
            this.props.loginData.data.mobileNumber
          );
          localStorage.setItem("_emailId", response.data.email);
          localStorage.setItem("status", "completed");
          Store.dispatch(
            LoginStateAction({
              isauth: true,
              step: 1,
              currentStep: 1,
              mobileNumber: this.props.loginData.data.mobileNumber,
              countryCode: this.props.loginData.data.countryCode,
              detail: this.props.loginData.data.detail,
              language: this.props.loginData.data.language,
              business: true,
            })
          );
          if (response.data.profile_check) {
            this.setState({ loading: false });
            var pendingArray = [];
            if (
              response.data.first_name == null ||
              response.data.first_name === "N/A"
            ) {
              pendingArray.push("first_name");
            }
            if (
              response.data.last_name == null ||
              response.data.last_name === "N/A"
            ) {
              pendingArray.push("last_name");
            }
            if (response.data.phone_number == null) {
              pendingArray.push("phone_number");
            }
            if (response.data.email == null) {
              pendingArray.push("email");
            }
            if (response.data.date_of_birth == null) {
              pendingArray.push("date_of_birth");
            }
            if (response.data.gender == null) {
              pendingArray.push("gender");
            }
            if (response.data.marital_status == null) {
              pendingArray.push("marital_status");
            }
            this.props.history.push("/pendingQues", { pendingArray });
          } else {
            this.setState({ loading: true });
            let pk_ = response.data.patient_n_key.split("-")[2];
            let jwt_token = response.data.jwt_token;
            window.location.replace(`${B_URL}/${pk_}/${jwt_token}/auth/user`);
          }
        } else if (response.status === "failed") {
          this.setState({
            loading: false,
            otpError: true,
            errorMessage: response.data,
          });
        } else {
          this.setState({ loading: false });
          console.log("OTP error", response);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("mobile number reponse error", err);
      });
  }

  verifyOtp = async (e) => {
    this.setState({ loading: true });

    if (e) {
      e.preventDefault();
    }

    let { otp } = this.state;
    let data = {
      phone_number: this.props.loginData.data.mobileNumber,
      typedotp: otp,
      dial_code: this.props.loginData.data.countryCode,
      app_language: this.props.loginData.data.language,
      ip_address: (await publicIp.v4()) || "",
    };
    if (!otp) {
      this.setState({ otpError: true, otpError: false });
    } else {
      if (this.props.loginData.data.business) {
        this.loadEmp(data);
      } else {
        this.setState({ otpError: false, loading: true });
        fetch(`${REACT_APP_API_URL}/register_patient/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then(async (res) => {
            if (res.status === "new_patient") {
              console.log("OTP reponse", res);
              localStorage.setItem("_userData", JSON.stringify(res.data));
              localStorage.setItem(
                "_initial",
                JSON.stringify(res.initial_check)
              );
              localStorage.setItem("_patientStatus", res.status);
              localStorage.setItem("_patientKey", res.data.patient_n_key);
              localStorage.setItem(
                "_appointmentKey",
                res.data.appointment_n_key
              );
              localStorage.setItem("_firstName", res.data.first_name);
              localStorage.setItem("_fullName", res.data.full_name);
              localStorage.setItem(
                "_mobileNumber",
                this.props.loginData.data.mobileNumber
              );
              localStorage.setItem("_emailId", res.data.email);
              localStorage.setItem("status", "completed");
              this.setState({ loading: false });

              if (res.data.profile_check) {
                var pendingArray = [];
                if (
                  res.data.first_name === null ||
                  res.data.first_name === "N/A"
                ) {
                  pendingArray.push("first_name");
                }
                if (
                  res.data.last_name === null ||
                  res.data.last_name === "N/A"
                ) {
                  pendingArray.push("last_name");
                }
                if (res.data.phone_number == null) {
                  pendingArray.push("phone_number");
                }
                if (res.data.email == null) {
                  pendingArray.push("email");
                }
                if (res.data.date_of_birth == null) {
                  pendingArray.push("date_of_birth");
                }
                if (res.data.gender == null) {
                  pendingArray.push("gender");
                }
                if (res.data.marital_status == null) {
                  pendingArray.push("marital_status");
                }
                this.props.history.push("/pendingQues", { pendingArray });
              } else this.props.history.push("/home");
            } else if (res.status === "old_patient") {
              localStorage.setItem("_userData", JSON.stringify(res.data));
              localStorage.setItem(
                "_initial",
                JSON.stringify(res.initial_check)
              );
              localStorage.setItem("_patientStatus", res.status);
              localStorage.setItem("_patientKey", res.data.patient_n_key);
              localStorage.setItem(
                "_appointmentKey",
                res.data.appointment_n_key
              );
              localStorage.setItem("_firstName", res.data.first_name);
              localStorage.setItem("_fullName", res.data.full_name);
              localStorage.setItem(
                "_mobileNumber",
                this.props.loginData.data.mobileNumber
              );
              localStorage.setItem("_emailId", res.data.email);
              localStorage.setItem("status", "completed");
              Store.dispatch(
                LoginStateAction({
                  isauth: true,
                })
              );

              if (res.data.profile_check) {
                var pendingArray = [];
                if (
                  res.data.first_name == null ||
                  res.data.first_name === "N/A"
                ) {
                  pendingArray.push("first_name");
                }
                if (
                  res.data.last_name == null ||
                  res.data.last_name === "N/A"
                ) {
                  pendingArray.push("last_name");
                }
                if (res.data.phone_number == null) {
                  pendingArray.push("phone_number");
                }
                if (res.data.email == null) {
                  pendingArray.push("email");
                }
                if (res.data.date_of_birth == null) {
                  pendingArray.push("date_of_birth");
                }
                if (res.data.gender == null) {
                  pendingArray.push("gender");
                }
                if (res.data.marital_status == null) {
                  pendingArray.push("marital_status");
                }

                this.props.history.push("/pendingQues", { pendingArray });
              } else {
                console.log(res.login_type);
                if (res.login_type == "Business") {
                  this.setState({ loading: true });
                  let pk_ = res.data.patient_n_key.split("-")[2];
                  let jwt_token = res.data.jwt_token;
                  window.location.replace(
                    `${B_URL}/${pk_}/${jwt_token}/auth/user`
                  );
                } else this.props.history.push("/home");
              }
            } else if (res.status === "failed") {
              this.setState({
                loading: false,
                otpError: true,
                errorMessage: res.data,
              });
            } else {
              this.setState({ loading: false });
              console.log("OTP error", res);
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log("mobile number reponse error", err);
          });
      }
    }
  };

  handleTime = () => {
    setTimeout(() => {
      this.setState({ sendNew: true });
      console.log("cece");
    }, 60000);
  };

  componentDidMount() {
    if (this.componentRef.current) {
      this.componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    console.log(this.props);
    this.handleTime();
  }

  render() {
    let { loading, errorMessage, otpError, sendNew } = this.state;
    let { t } = this.props;
    return (
      <>
        <div ref={this.componentRef} className="choose-language-outer">
          {loading && <Loader />}
          <div className="d-flex">
            <Col md={6} className="mx-auto mt-2 mt-md-1 pt-md-1 pt-1">
              <div className="choose-language-inner px-md-4 px-2">
                <div className="otp_sec">
                  <p className="text-center">Code sent!</p>
                  <h6>Verify Your Contact Information</h6>
                  <div className="text-center _header_font text-white h5">
                    Enter the code we just sent to
                    <br />
                    {this.props.loginData.data.email ||
                      this.props.loginData.data.countryCode +
                        " " +
                        this.props.loginData.data.mobileNumber}
                  </div>
                  <form onSubmit={this.verifyOtp}>
                    <div className="mx-auto col-md-10 reactinput mt-4 ">
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={6}
                        separator={<span> </span>}
                      />
                    </div>
                    {otpError && (
                      <p className="errorMesage mt-3">{errorMessage}</p>
                    )}
                    <h4 className="mt-md-4 mt-3 text-center">
                      Your code may take up to a minute to arrive.
                      {sendNew ? (
                        <span onClick={() => this.resendOtp()}>
                          Send New Code!
                        </span>
                      ) : (
                        <span style={{ color: "grey" }}>Send New Code!</span>
                      )}
                    </h4>
                    <div className="text-center mx-auto py-md-2 py-1">
                      <button
                        type="submit"
                        className="btn btn-primary w-100  mt-md-5 mt-2"
                      >
                        {" "}
                        {t("Verify")}{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(OtpValidation)));
