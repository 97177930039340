import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import LeftContent from "./LeftContent";
import MobileNumber from "./MobileNumber";
import OtpValidation from "./OtpValidation";
import RegistrationForm from "./RegistrationForm";
import SignUp from "./SignUp";
import { withRouter } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "",
      mobileNumber: "",
      countryCode: "",
      business: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginData !== this.props.loginData) {
      this.setState({
        step: this.props.loginData.data?.step,
        mobileNumber: this.props.loginData.data?.mobileNumber,
        countryCode: this.props.loginData.data?.countryCode,
      });
    }
  }

  componentDidMount() {
    console.log(this.props, window.location.pathname);
    if (this.props.loginData?.data?.fromreg_) {
      this.navigateComponent();
    }
  }
  navigateComponent = (id, business) => {
    this.setState({
      step: id || this.props.loginData?.data?.step || "",
      business: business ? true : false,
    });

    if (id == 0) {
      const { history } = this.props;
      history.push("/signup", { business: business ? true : false });
    }
  };

  render() {
    const { step, business } = this.state;
    return (
      <>
        {step === "" ? (
          <SignUp navigateComponent={this.navigateComponent} />
        ) : (
          <div className={"login-main"}>
            <Row className="m-0">
              <Col
                md={8}
                className="col-sm-push-12 order-lg-12  px-0 px-md-auto pr-0"
              >
                {step === 0 && <MobileNumber />}
                {step === 1 && <OtpValidation />}
                {step === 2 && <RegistrationForm />}
              </Col>
              <Col
                md={4}
                className={
                  step === 2
                    ? "col-sm-push-1 order-lg-1   px-0 register-left"
                    : "col-sm-push-1 order-lg-1   px-0"
                }
              >
                <LeftContent
                  currentStep={this.props.loginData.data?.currentStep}
                  step={step}
                  navigateComponent={this.navigateComponent}
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default withRouter(connect(mapStateToProps, null)(Login));
