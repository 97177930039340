import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeft, ArrowLeftCircle, ArrowRight, Flag } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import Loader from '../../Loader';
import { REACT_APP_API_URL, REACT_APP_BASEURL } from '../../../processENV';
import axios from 'axios';
class Asses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            quesObj: [],
            bulkData:{},
            step:0,
            indx:0,
        };
    }
   
getQues=async()=>{
    const headers={
        "Content-Type": "application/json",
        "Authorization": "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf"
       }
        axios.get(REACT_APP_BASEURL+`/api/orderform_details/${1}/`,{headers}).then((res)=>{
          console.log(res)
          this.setState({
              bulkData:res.data,
              quesObj:res.data.form_data
          })
      })      
}
   
    componentDidMount() {
       this.getQues() 
    }

    selectproblem = ( value) =>{
            Store.dispatch(ScheduleAction({
               problem:this.props.scheduleData.data.problem,
               scheduleStep:2,
               scheduleCurrentStep:2,
            }));
    }
    
    goBack = () =>{
        Store.dispatch(ScheduleAction({
            scheduleStep:0,
            scheduleCurrentStep:0,
            mode:this.props.scheduleData.data.mode,
         })); 
    }
    sendFront=()=>{
        if(this.state.indx+1 !== this.state.quesObj.length){
            this.setState({indx:this.state.indx+1})
        }
        else if(this.state.indx+1 == this.state.quesObj.length){
          console.log('nil')
      }
        else{
            console.log('end')
        }
    }
    goFront=()=>{
            Store.dispatch(ScheduleAction({
                problem:this.props.scheduleData.data.problem,
                scheduleStep:2,
                scheduleCurrentStep:2,
             }));
    }
    handleQues = async(indx, vals) => {
            this.setState({
             quesObj: this.state.quesObj.map((item, index) => {
                if (indx !== index) return item;
                return { ...item, user_answer: vals };
              })
               });
               if(this.state.indx+1 == this.state.quesObj.length){
                this.setState({disable:false})
            }
             setTimeout(()=>{
              this.sendFront()
             },200)
          };
          submitQues=async()=>{
            const data = {
                // link_id: 3,
                patient_n_key:localStorage.getItem('_patientKey') ,
                form: this.state.bulkData.title,
                order_form_id: this.state.bulkData.order_form_id,
                values: this.state.quesObj,
              };
            const headers = {
                "Content-Type": "application/json",
                Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
              };
              console.log(
                REACT_APP_API_URL+`/therpayform_post/`,
                data
              );
              axios
                .post(
                    REACT_APP_API_URL+`/therpayform_post/`,
                  data,
                  { headers }).then((res)=>{
                      console.log(res)
                        this.goFront()
                    
                  })
        }
    render() {
        const { step,indx, bulkData,loading, quesObj,} = this.state;
        return (
            <>
                <div className="col-md-8 mx-auto mt-5 pt-5 position-relative">
                <a onClick={this.goBack}className="p-4 m-4">
                <ArrowLeftCircle size={25} /></a>
                     {loading && <Loader />}
                    <div className="d-flex mx-auto p-0">
                        <Col  className="mx-auto text-center ">
                        {step ==0 ? <div className='mt-3 pt-4 text-center'>
                             <p style={{color:'#215352'}} className='text_p_ h3 text_medium_ text-center'>
                             Next, we'll ask you about the symptoms you're experiencing, then help you understand what they mean and what you can do about them. You must be honest and respond as accurately as possible.
                             </p>
                             <button className='btn_Schdule mt-4' onClick={()=>{this.setState({step:1})}}>Continue</button>
                         </div>:null}
                        {step ==1 ? <div>
                            {bulkData &&quesObj&& quesObj.length!==0 && 
                            <div className='mt-5 text-left'>
                                <h4 style={{fontSize:'20px',color:'#215352'}} className='h5 header_font_ text-left font-weight-bold'>{bulkData.description}</h4> 
                                <p style={{fontSize:'18px',color:'#215352'}} className='text_p_'>{indx+1}<ArrowRight /> {quesObj[indx].question}</p>
                                  {quesObj[indx].answer.map((i,inx)=>{
                                      return(
                                        <div className='d-flex '> 
                                        <button style={{fontFamily:'Hanken Grotesk',fontSize:'16px'}} className={`${ i===quesObj[indx].user_answer ?'btn-info':null} m-2 bordered shadow-sm text_p_ btn_ases_ col-md-3 col-sm-2 text-left font-weight-bold p-3`} onClick={()=>this.handleQues(indx,i)}>
                                          {i}
                                        </button>
                                        </div>
                                      )
                                  })} 
                           </div>}
                           <div className='row mx-auto m-4 p-4 d-flex flex-row justify-content-center'>
                           
                           {indx >0 && <div onClick={()=>this.setState({indx:indx-1})} className='m-2'>
                           <button onClick={()=>this.setState({indx:indx-1})} className='btn_Schdule'><ArrowLeft /> Previous </button>
                             </div>}
                             {indx === quesObj.length -1 && <div className='m-2'>
                            <button className='btn_Schdule' onClick={()=>this.submitQues()}>Submit <ArrowRight /></button>
                        </div>}
                           </div>
                          
                        </div>:null} 
                       
                         </Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        scheduleData: state.ScheduleData
    }
}

export default connect(mapStateToProps, null)(Asses);
