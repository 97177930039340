import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { ArrowRight, Edit, Trash } from "react-feather";
import Flatpickr from "react-flatpickr";
import { withTranslation } from "react-i18next";
import { AiFillCheckSquare } from "react-icons/ai";
import { FaUserAlt, FaUserPlus } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { RiContactsBookFill } from "react-icons/ri";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  Row
} from "reactstrap";
import { CURE_URL, REACT_APP_API_URL} from "../../processENV";
import Loader from "../Loader";
import { dialdata } from "../../../Helpers/dialdata";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.fileinp = React.createRef();
    this.state = {
      isOpen: false,
      activeTab: "1",
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      maritalStatus: "",
      age: "",
      error: "",
      phone_number:'',
      loading: false,
      mainUser: [],
      familyUser: [],
      address: "",
      city: "",
      state: "",
      pincode: "",
      community_profile: {},
      care_person: {},
      gen_modal: false,
      avatar_modal: false,
      avatar: [],
      user_uri: "",
      user_img: "",
      plainImg: "",
      editable: true,
      imag_edit: true,
      opts: [
        "Male",
        "Female",
        "Non-Binary",
        "Transgender",
        "Something Else",
        "I choose Not To Disclose",
      ],
    };
  }

  verticaltoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  setImg = (id, idx) => {
    let filt = this.state.avatar.filter((i) => i.id === id)[0];
    let dat = this.state.avatar;
    dat[idx].select = true;
    this.setState({
      community_profile: {
        ...this.state.community_profile,
        avatar: filt.name,
      },
      user_img: filt.src,
      avatar: dat,
    });
    setTimeout(() => {
      this.setState({
        avatar_modal: false,
      });
    }, 700);
  };
  componentDidMount() {
    this.getUserDetails();
    this.getAvat();
  }
  getAvat = () => {
    const data = {
      user_key: localStorage._patientKey,
    };
    fetch(`${CURE_URL}/community/community_auth_user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("user_Verify", res);
        this.setState({ avatar: res.avatar });
      })
      .catch((err) => console.log(err));
  };
  getUserDetails = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    this.setState({ loading: true });

    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("user details", res);
        if (res) {
          this.setState({
            loading: false,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            dob: res.data.date_of_birth,
            gender: res.data.gender,
            maritalStatus: res.data.marital_status,
            age: res.data.age,
            address: res.data?.address_line_one,
            city: res.data.city,
            state: res.data.state,
            pincode: res.data.pincode,
            community_profile: res.community_data,
            care_person: res.data.care_person,
            user_uri: res.data.profile_picture,
            phone_number:res.data.dial_code+' '+res.data.phone_number,
          });
        } else {
          this.setState({
            loading: false,
            error: "Some error has occured please try again",
          });
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginData !== this.props.loginData) {
      this.setState({
        step: this.props.loginData.data?.step,
        mobileNumber: this.props.loginData.data?.mobileNumber,
        countryCode: this.props.loginData.data?.countryCode,
      });
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  profileInputHandler = (e) => {
    let id = e.target.name;
    this.setState({
      [id]: e.target.value,
    });
  };

  handleDateChange = (ddd) => {
    if (ddd === null || ddd === "") {
      this.setState({
        dob: null,
      });
    } else {
      let date = new Date(ddd);

      let dd =
        date.getDate.toString().length === 1
          ? "0" + date.getDate()
          : date.getDate();
      let mm =
        date.getMonth().toString().length === 1
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let yy =
        date.getFullYear().toString().length === 1
          ? "0" + date.getFullYear()
          : date.getFullYear();

      let fromDate = yy + "-" + mm + "-" + dd;
      this.setState({
        dob: fromDate,
      });
    }
  };

  handlClick = async () => {
    this.fileinp.current.click();
  };
  handleFile = async (event) => {
    let res = event.target.files[0];
    console.log(res);
    this.setState({
      user_uri: URL.createObjectURL(event.target.files[0]),
      plainImg: event.target.files[0],
      imag_edit: true,
    });
  };
  handeImg = async () => {
    const { plainImg } = this.state;
    if (plainImg.length !== 0) {
      console.log(plainImg, localStorage.getItem("_patientKey"));
      var data = new FormData();
      data.append("profile_picture", plainImg);
      data.append("patient_n_key", localStorage.getItem("_patientKey"));
      console.log(data);
      for (var pair of data.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      axios({
        method: "post",
        url: `${REACT_APP_API_URL}/profilepicture_post/`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      }).then(async (res) => {
        if (res.status === "success") {
          console.log("suces");
          this.setState({ plainImg: "" });
          this.getUserDetails();
          window.location.href = "/patientprofile";
        } else {
          console.log("res");
        }
      });
    }
  };
  handComm = async () => {
    const { community_profile } = this.state;
    if (community_profile.community_name.length !== 0) {
      let data = {
        user_key: localStorage.getItem("_patientKey"),
        community_name: community_profile.community_name||'Anonymous',
        image: community_profile.avatar,
      };
      fetch(`${CURE_URL}/community/community_user_update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === "success") {
            console.log("community_name_Success");
            this.getUserDetails();
          } else {
            console.log("community_name_error");
          }
        });
    }
  };
 isValidEmail(email) {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
  validateForm(firstName, lastName, dob, gender, care_person) {
    console.log(firstName, lastName, dob, gender, care_person)
    if (!firstName) {
      toast.error("FirstName is required");
      return false;
    }
    if (!lastName) {
      toast.error("LastName is required");
      return false;
    }
    if (!dob) {
      toast.error("DOB is required");
      return false;
    }
    if (!gender) {
      toast.error("Gender is required");
      return false;
    }
    if (care_person?.care_person_email && !this.isValidEmail(care_person?.care_person_email)) {
    toast.error("Enter a valid emergency contact email");
    return false;
    }
    if (!care_person?.care_person_name) {
      toast.error("Emergency contact name is required");
      return false;
    }
  
    const dialCode = care_person?.care_person_dial||'+91';
    const carePersonNumber = care_person?.care_person_number?.trim();
    if (!dialCode) {
      toast.error("Emergency contact dial code is required");
      return false;
    }
    if (dialCode === '+91' && (carePersonNumber?.length !== 10)) {
      toast.error("Enter a valid emergency contact");
      return false;
    }
    if (dialCode !== '+91' && (carePersonNumber?.length < 3)) {
      toast.error("Enter a valid emergency contact");
      return false;
    }
    
    return true;
  }
  
  handleSubmit = () => {
    // this.handeImg();
    this.handComm();
    let {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      age,
      address,
      state,
      pincode,
      city,
      care_person
    } = this.state;
    console.log(this.validateForm(firstName, lastName, dob, gender, care_person))
  if(this.validateForm(firstName, lastName, dob, gender, care_person)){
      let careperson = {
        care_person_name: this.state.care_person.care_person_name,
        care_person_email: this.state.care_person.care_person_email,
        care_person_number: this.state.care_person.care_person_number,
        care_person_dial: this.state.care_person.care_person_dial,
      };
      let data = {
        patient_n_key: localStorage.getItem("_patientKey"),
        first_name: firstName,
        last_name: lastName,
        marital_status: maritalStatus,
        age: age,
        date_of_birth: dob,
        gender: gender,
        address_line_one: address,
        city: city,
        state: state,
        pincode: pincode,
        care_person: careperson,
      };
      this.setState({ loading: true, error: "" });
      console.log("smoking data", data);
      fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === "success") {
            this.setState({
              loading: false,
              editable:true,
              imag_edit:true,
            });
            this.getUserDetails();
            this.getAvat();
            toast.success('Profile updated!')
          } else {
            this.setState({
              loading: false,
              error: "Some error has occured please try again",
            });
          }
        });
    }
  };

  render() {
    let {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      age,
      error,
      loading,
      address,
      city,
      state,
      pincode,
      care_person,
      community_profile,
      avatar,
      user_img,
      opts,
      user_uri,
      editable,
      imag_edit,
    } = this.state;
    let { t } = this.props;
    return (
      <>
        <ToastContainer />

        <div>
          <Container className="px-0">
            <Row className="profilecard mt-5 pt-5 overflow-auto mx-auto px-0">
              {loading && <Loader />}

              <Modal centered isOpen={this.state.avatar_modal}>
                <ModalBody>
                  <div className="avatar_container">
                    <ul className="avatar_cards">
                      {avatar &&
                        avatar.map((i, index) => {
                          return (
                            <li key={index} className="avatar_card">
                              <img
                                className={`${i.select ? "back_img" : null}`}
                                onClick={() => this.setImg(i.id, index)}
                                src={i.src}
                                alt=""
                              />
                              {i.select ? (
                                <AiFillCheckSquare
                                  className="inside_icon"
                                  size={18}
                                  color={"green"}
                                />
                              ) : null}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </ModalBody>
              </Modal>

              <Modal centered isOpen={this.state.gen_modal}>
                <ModalBody>
                  {opts.map((i, indx) => {
                    return (
                      <div
                        onClick={() =>
                          this.setState({ gender: i, gen_modal: false })
                        }
                        className="gen_modal_item"
                      >
                        {i}
                      </div>
                    );
                  })}
                </ModalBody>
              </Modal>

              <Col sm="9" md="9" xs={12} className="mb-2 mx-auto px-0">
                <Row className="mx-auto px-0">
                  <Col md={8} xs={12} className="mb-2 mx-auto px-0">
                    <Card className="px-0 ">
                      <button
                        onClick={() =>
                          editable
                            ? this.setState({
                                editable: false,
                                imag_edit: false,
                              })
                            : this.handleSubmit()
                        }
                        className="edit_top_btn"
                      >
                        {editable ? "Edit" : "Save"}
                      </button>
                      {imag_edit ? (
                        user_uri ? (
                          <>
                            <img src={user_uri} className="name_img_" />
                            {this.state.plainImg ? (
                              <Trash
                                size={16}
                                style={{ marginTop: "-10px" }}
                                onClick={() =>
                                  this.setState({
                                    plainImg: "",
                                    user_uri: "",
                                    imag_edit: false,
                                  })
                                }
                                color={"red"}
                                className="inp_edit_"
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className="name_img">
                            <p className="round_p">
                              {firstName.charAt(0).toUpperCase()}
                            </p>
                          </div>
                        )
                      ) : (
                        <>
                          <input
                            className="prof_inpt_file"
                            accept="image/png, image/jpeg"
                            onChange={this.handleFile}
                            ref={this.fileinp}
                            type="file"
                          />
                          <label
                            onClick={() => this.handlClick()}
                            className="lab_inp"
                          >
                            <Edit size={18} className="inp_edit" />{" "}
                          </label>
                        </>
                      )}

                      <CardBody className="col-md-12 mx-auto">
                        <Formik
                          initialValues={{
                            name: "",
                            email: "",
                          }}
                          onSubmit={this.profileSubmitHandler}
                        >
                          {({ errors, touched }) => (
                            <Form>
                              <h3
                                style={{ marginTop: "10px" }}
                                className="header_h3"
                              >
                                <FaUserAlt size={25} /> About Me
                              </h3>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div">
                                  <label>{t("First Name")}</label>
                                  <Field
                                    name="firstName"
                                    disabled={this.state.editable}
                                    className="form-control prof_inp"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="firstName" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div pro_col">
                                  <label>{t("Last Name")}</label>

                                  <Field
                                    name="lastName"
                                    disabled={this.state.editable}
                                    className="form-control inp_col"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.email && touched.email ? (
                                    <ErrorMessage name="lastName" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="w-100 col-md-12 px-md-0">
                                <div className="prof_div">
                                  <label>{t("Date of Birth")}</label>
                                  <Flatpickr
                                    className="form-control w-100"
                                    placeholder="Select Date of Birth"
                                    disabled={this.state.editable}
                                    selected={dob}
                                    value={dob}
                                    options={{ maxDate: new Date() }}
                                    onChange={(date) =>
                                      this.handleDateChange(date)
                                    }
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup className="w-100 col-md-12 px-md-0 m-0">
                                <div className="prof_div pro_col">
                                  <label>Gender</label>
                                  <Field
                                    name="profile"
                                    onClick={() =>
                                      this.setState({ gen_modal: true })
                                    }
                                    className="form-control inp_col"
                                    placeholder="Gender"
                                    value={gender}
                                    disabled={this.state.editable}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.profile && touched.profile ? (
                                    <ErrorMessage name="gender" />
                                  ) : null}
                                </div>
                              </FormGroup>
                               <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div">
                                  <label>Phone Number</label>
                                  <Field
                                    name="phonenumber"
                                    disabled={true}
                                    className="form-control prof_inp"
                                    placeholder="First Name"
                                    value={this.state.phone_number}
                                    onChange={(e) => this.profileInputHandler(e)}
                                  />
                                </div>
                              </FormGroup>
                              <h3 className="header_h3">
                                <HiUserGroup size={25} /> Community Profile
                              </h3>
                              <div className="prof_div">
                                  <label>Community Name</label>
                                  <Field
                                    name="community_name"
                                    disabled={this.state.editable}
                                    className="form-control prof_inp"
                                    placeholder="Community Name"
                                    value={this.state.community_profile?.community_name}
                                    onChange={(e) =>
                                      this.setState({community_profile:{...community_profile,community_name:e.target.value}})
                                    }
                                  />
                                </div>
                              <h3 className="header_h3">
                                {" "}
                                <RiContactsBookFill size={25} /> Contact Details
                              </h3>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div">
                                  <label>{t("Address")}</label>
                                  <Field
                                    name="address"
                                    disabled={this.state.editable}
                                    className="form-control"
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="Address" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div pro_col">
                                  <label>{t("City")}</label>
                                  <Field
                                    name="city"
                                    disabled={this.state.editable}
                                    className="form-control inp_col"
                                    placeholder="City"
                                    value={city}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="City" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div ">
                                  <label>{t("State")}</label>
                                  <Field
                                    name="state"
                                    disabled={this.state.editable}
                                    className="form-control"
                                    placeholder="State"
                                    value={state}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="State" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div pro_col">
                                  <label>{t("PinCode")}</label>
                                  <Field
                                    name="pincode"
                                    className="form-control inp_col"
                                    placeholder="PinCode"
                                    disabled={this.state.editable}
                                    value={pincode}
                                    onChange={(e) =>
                                      this.profileInputHandler(e)
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="PinCode" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <h3 className="header_h3">
                                {" "}
                                <FaUserPlus size={25} /> Emergency Contact
                                Details
                              </h3>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div">
                                  <label>Full Name</label>
                                  <Field
                                    name="Full name"
                                    className="form-control"
                                    placeholder="Full name"
                                    disabled={this.state.editable}
                                    value={care_person.care_person_name}
                                    onChange={(e) =>
                                      this.setState({
                                        care_person: {
                                          ...this.state.care_person,
                                          care_person_name: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="PinCode" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div pro_col">
                                  <label>EmailID</label>
                                  <Field
                                    name="EmailID"
                                    className="form-control inp_col"
                                    placeholder="EmailID"
                                    disabled={this.state.editable}
                                    value={care_person.care_person_email}
                                    onChange={(e) =>
                                      this.setState({
                                        care_person: {
                                          ...this.state.care_person,
                                          care_person_email: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="PinCode" />
                                  ) : null}
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div">
                                  <label>Dial code</label>
                                  <select 
                                  style={{border:'none'}}
                                  name="countryCode" 
                                  className="form-control col-md-3  border-none ml-auto text-right"
                                  value={this.state.care_person.care_person_dial} placeholder="" 
                                  onChange={(e)=>{
                                    this.setState({
                                      care_person: {
                                        ...this.state.care_person,
                                        care_person_dial: e.target.value,
                                      },
                                    })
                                  }}>
                                    {dialdata.map((item, i)=>{
                                    return   <option key={item.name} value={item.code}>{item.code} {item.name}</option>
                                    })}
                                  </select>
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-12 pl-md-0">
                                <div className="prof_div pro_col">
                                  <label>Contact Number</label>
                                  <Field
                                    name="pincode"
                                    className="form-control"
                                    disabled={this.state.editable}
                                    placeholder="Contact number"
                                    value={care_person.care_person_number}
                                    onChange={(e) =>{
                                      this.setState({
                                        care_person: {
                                          ...this.state.care_person,
                                          care_person_number: e.target.value,
                                        },
                                      })
                                    }}
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage name="PinCode" />
                                  ) : null}
                                </div>
                              </FormGroup>
                            </Form>
                          )}
                        </Formik>{" "}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(Profile));
