import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeftCircle, Flag } from 'react-feather';
import Store from '../../../Store';
import { RescheduleAction } from '../../../Actions/RescheduleAction';
import Loader from '../Loader';
import { REACT_APP_API_URL } from '../../processENV';
import SelectTime from './SelectTime';

class SelectDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            date: [],
            selectedDate:'',
            spots : [],
            empId:'',
            prodId:'',
            selected:false
        };
    }
getDate=()=>{
    let prodId = this.props?.rescheduleData?.data.prodId?.appointment_n_key
    let empId = this.props?.rescheduleData?.data.prodId?.doc_app_id
    let gkey=this.props?.rescheduleData?.data.prodId?.app_prod_grp_key
    this.setState({prodId, empId})
    console.log(prodId, empId)
let data = {
    "employee_n_key":empId,
    "appointment_n_key":prodId,
    "app_prod_grp_key":gkey
}
    fetch(`${REACT_APP_API_URL}/app_rescheduled_date/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
            console.log("appoint date",res)
            if(res.status === "success"){
                this.setState({loading:false, date:res.data, spots:res.count})
            }else{
                this.setState({loading:false,error:'Some error has occured please try again'})
            }
        })
}
    componentDidMount() {
        let item = this.props
        console.log(item)
      
    this.setState({loading:true})
   this.getDate()
       
    }

    selectDate = ( value) =>{
        console.log(value)
        this.setState({selectedDate:value, selected:true},()=>{
            Store.dispatch(RescheduleAction({
                rescheduleStep:2,
                rescheduleCurrentStep:1,
                // date: this.state.selectedDate,
                // time: this.props.rescheduleData.data?.time,
                // prodId:this.props.rescheduleData.data.prodId,
                // empId: this.state.empId
            }));
        })
        
    }
    
    goBack = () =>{
        Store.dispatch(RescheduleAction({
            rescheduleStep:0,
            rescheduleCurrentStep:1,
            date: this.state.selectedDate,
            time: this.props.rescheduleData.data?.time,
            prodId:this.props.rescheduleData.prodId,
            empId: this.state.empId
        }));
    }

    render() {
        let { date, time, selected,loading, selectedDate, selectedTime, spots } = this.state;
        return (
            <>
               {selected? <SelectTime prod={this.state.prodId} date={this.state.selectedDate} />:<>   
               <div className="col-md-8 mx-auto bg-none mt-5 pt-5">
                <a onClick={this.goBack}className="p-4 m-4">
                <ArrowLeftCircle size={25} /></a>
                     {loading && <Loader />}
                    <div className="d-flex mx-auto mt-5">
                        <Col  className="mx-auto text-center">
                        <p style={{fontSize:'26px'}} className='h5 header_font_ font-weight-bold'>You can schedule your appointment date now based on your convenience and check your meeting schedule on your home screen. </p>
                            {selectedDate && <p style={{fontSize:'16px'}} className='h4 header_font_ font-weight-bold'>{selectedDate}</p>}
                            <div className=" mt- 5 pt-5 col-md-6 mx-auto">
                                {date && date.map((item, i)=>{
                                    return <p style={{fontFamily:'Hanken Grotesk',fontSize:'20px'}} className={'p-3 border text_p_ shadow-sm text_medium_ position-relative'} key={i} onClick={()=>this.selectDate(item)}>{item}
                                     <small style={{fontSize:'12px',right:5,bottom:3}} className='text-right position-absolute font-weight-bold'>{spots[i]} slots </small>
                                    </p>
                                })}

                            </div></Col>

                    </div>
                </div></>}
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        rescheduleData: state.RescheduleData
    }
}

export default connect(mapStateToProps, null)(SelectDate);
