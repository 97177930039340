import React from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Flag } from "react-feather";
import Store from "../../../Store";
import { LoginStateAction } from "../../../Actions/LoginAction";

import i18n from "i18next";

class ChooseLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [
        "Tamil",
        "English",
        "Malayalam",
        "Kanada",
        "Telugu",
        "Bengal",
        "Urdu",
        "Hindi",
      ],
      code: ["tam", "en", "mal", "kan", "tel", "ben", "ur", "hin"],
      active: localStorage.getItem("_language"),
    };
  }

  componentDidMount() {
    this.setState({ active: localStorage.getItem("_language") });
  }

  selectLanguage = (lang) => {
    this.setState({ active: this.state.languages[lang] });
    i18n.changeLanguage(this.state.code[lang]);
    localStorage.setItem("_currentStep", 1);
    localStorage.setItem("_language", this.state.languages[lang]);
    Store.dispatch(
      LoginStateAction({
        step: 1,
        currentStep: 1,
        mobileNumber: "",
        countryCode: "",
        otp: "",
        language: this.state.languages[lang],
      })
    );
  };

  render() {
    let { languages, active, code } = this.state;
    return (
      <>
        <div className="login-main homepage">
          <div className="choose-language-outer">
            <div className="d-flex">
              <Col md={4} className="mx-auto">
                <div className="choose-language-inner">
                  <p>
                    We speak your language.
                    <br /> So feel free to choose one
                  </p>
                  <div className="choose-language-card">
                    <div className="languagesec">
                      {languages.map((item, i) => {
                        return (
                          <p
                            className={`choose-language-item   ${
                              active === item ? "active" : ""
                            }`}
                            key={i}
                            onClick={() => this.selectLanguage(i)}
                          >
                            {" "}
                            <Flag
                              size={18}
                              className={
                                active === item ? "visible" : "invisible"
                              }
                            />{" "}
                            {item}{" "}
                          </p>
                          // <p className={`choose-language-item   ${item=== "English" ? "active" : "blocked"}`} key={i} onClick={item  === "English" ? () => this.selectLanguage(item):''}> <Flag size={18} className={ item  === "English" ? 'visible' : 'invisible'} /> {item} </p>
                        ); //${this.state.active === item ? "active" : ""}`} {this.state.active === item ? 'visible' : 'invisible'}
                      })}
                    </div>{" "}
                    <hr></hr>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(ChooseLanguage);
