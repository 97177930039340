import React from "react";
import { Container, Row } from "reactstrap";
import { connect } from "react-redux";

import Reviews from "./Reviews";
import MainSection from "./MainSection";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginData !== this.props.loginData) {
      this.setState({
        step: this.props.loginData.data?.step,
        mobileNumber: this.props.loginData.data?.mobileNumber,
        countryCode: this.props.loginData.data?.countryCode,
      });
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    let { isOpen } = this.state;
    return (
      <>
        <div className={"login-main homecontainer mx-auto"}>
          <Container className="m-0 p-0 mx-md-auto">
            <Row className="p-md-2 px-2 ml-md-5 mx-md-auto ">
              <MainSection />
            </Row>
            <Reviews />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(HomePage);
