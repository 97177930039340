import React, { useEffect, useState } from 'react'
import { patientResponse, postapi, postdata } from '../../../../Helpers/Api';
import { Button, Card, Col, Container, DropdownButton, Overlay, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FaFontAwesome, FaFunnelDollar, FaLanguage, FaVideo } from 'react-icons/fa';
import { connect } from 'react-redux';
import { REACT_APP_API_URL, RET_URL, SITE_URL, getCurrencySymbol } from '../../../processENV';
import { ChatBubble, ConfirmationNumber, DateRange, FilterListOutlined, FormatShapesSharp, InfoOutlined, InfoRounded, TimerRounded } from '@material-ui/icons';
import { ArrowLeftCircle, Filter, Phone } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import CustomCheckboxDropdown from './DropdownWithCheckbox';
import Loader from '../../Loader';
import CheckboxSection from './FilterComponent';
import Sidebar from 'react-sidebar';
import { CloseCircleSharp } from 'react-ionicons';
import { IoCloseSharp, IoInformationCircleOutline, IoLanguage } from 'react-icons/io5';


const TherapistFilter = (props) => {
    const {scheduleData,item}=props;
    const [isVisible, setIsVisible] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading]=useState(false);
    const [therapistList, setTherapistList] = useState([]);
    const [open, setOpen]=useState(false);
    const [ageGroup,setAgeGroup]=useState('')
    const [ageGroupData,setAgeGroupData]=useState([])
    const [experienceData, setExperienceData] = useState([
      { label:"1+ years",name: "1+ years",value: 1, checked: false },
      { label:"2+ years",name: "2+ years",value: 2, checked: false },
      { label:"4+ years",name: "4+ years",value: 4, checked: false },
      { label:"6+ years",name: "6+ years",value:6, checked: false },
      { label:"8+ years",name: "8+ years",value:8, checked: false },
      { label:"10+ years",name: "10+ years",value:10, checked: false },
    ]);
    const [experience, setExperience] = useState(null);
    const [availabilitydata, setAvailabilityData] = useState([
      {label:'Today', name: "Today",value:'Today', checked: false },
      {label:'Tomorrow', name: "Tomorrow",value:'Tomorrow', checked: false },
      { label:'This week',name: "This week",value:'This week', checked: false },
    ]);
    const [availability, setAvailability] = useState(null);
    const [modes, setModes] = useState([]);
    const [mode, setMode] = useState(null);
    const [languageData, setLanguageData] = useState([]);
    const [language, setLanguage] = useState(null);
    const [genderData, setGenderData] = useState([]);
    const [gender, setGender] = useState(null);
    const [modalitydata, setModalityData]=useState([]);
    const [sortvalue,setSortValue]=useState('')
    const [modality,setModality]=useState('')
    const [specialitydata, setSpecialityData]=useState([]);
    const [speciality,setSpeciality]=useState('');
    const [concerns, setConcerns]=useState('');
    const [concernData, setConcernData]=useState([]);
    const [showReadMoreSpeciality, setShowReadMoreSpeciality] = useState(false);
    const [showReadMoreTherapyStyle, setShowReadMoreTherapyStyle] = useState(false);
    const [suggested,setSuggested]=useState(false)
    const [drop1, setDrop1]=useState(false)
    const [drop2, setDrop2]=useState(false)
    const [drop3, setDrop3]=useState(false)
    const [drop4, setDrop4]=useState(false)

    useEffect(() => {
        getTherapistProfile();
    }, [gender,mode,language,availability,experience,ageGroup]);

    useEffect(()=>{
        getTherapistProfile();
        getLanguages(); 
        setLanguage(scheduleData?.data?.language);
        if(item?.prod?.app_prod_grp_key !== 'APP-PROD-CAT-1'||item?.app_prod_grp_key !== 'APP-PROD-CAT-1'){
          setMode('Video')
          }
          console.log(item)
    },[])

    const getLanguages=async()=>{
        const data = { patient_n_key: localStorage._patientKey};
        const resp = await postapi('/book_app_product/', data);
        if (resp.status == "success") {
          setConcernData(resp.data.map((i)=>({label:i.product_title, name:i.product_title,value:i.app_prod_n_key,checked:i.app_prod_n_key===scheduleData.data?.problem?.app_prod_n_key})));
          setConcerns(resp.data.filter((i)=>i.app_prod_n_key === scheduleData.data?.problem?.app_prod_n_key)[0]?.product_title)
        } else{ console.log("something went wrong")};
        const res = await postdata("product_filters/", {app_prod_grp_key:props?.item?.app_prod_grp_key,patient_n_key:localStorage._patientKey});
        if(res.status==='success'){
          console.log(res.speciality)
          setAgeGroupData(res.age_group.map((i)=>({label:i,name:i,value:i,checked:false})))
          setGenderData(res.gender.map((i)=>({label:i,name:i,value:i,checked:false})))
          setLanguageData(res.languages.map((i)=>({label:i,name:i,value:i,checked:scheduleData?.data?.language ===i})))
          // setModalityData(res.modality.map((i)=>({name:i,value:i,checked:false})))
          setModes(res.mode.map((i)=>({label:i,name:i,value:i,checked:false})))
          setSpecialityData(res.speciality.map((i)=>({label:i,name:i,value:i,checked:false})))
        }
    }
    const getTherapistProfile = async () => {
        setLoading(true)
        const appoitObject = props?.item;
        const data = {
          patient_n_key: localStorage._patientKey,
          app_prod_grp_key: appoitObject?.app_prod_grp_key,
          languages: language||scheduleData?.data?.language,
          gender: gender,
          modality: modality?[modality]:null,
          mode: mode?[mode]:null,
          age_group: ageGroup?[ageGroup]:null,
          speciality: speciality?[speciality]:null,
          availability: availability,
          experience: experience,
        };
        console.log(data)
        const res = await postdata("list_emp_profile/", data);
        console.log(res);
        if (res.status === "success") {
          setLoading(false)
          if(res.data.length > 0){
            setTherapistList(res.data);
            setSuggested(false)
          }
          else{
            setTherapistList(res.suggested_data);
            setSuggested(true)
          }
        }
        
      };
      const handleSort =(key)=>{
        let sortedList = [...therapistList];
        setSortValue(key)
        switch (key) {
          case '1':
            sortedList.sort((a, b) => a.followup_price - b.followup_price);
            break;
          case '2':
            sortedList.sort((a, b) => b.followup_price - a.followup_price);
            break;
          case '3':
            sortedList.sort((a, b) => b.experience_value - a.experience_value);
            break;
            case '4':
              sortedList.sort((a, b) => {
                const dateA = new Date(a.next_available_format); 
                const dateB = new Date(b.next_available_format); 
                return dateA - dateB;
              });
              break;
          default:
            // No sorting, or you can set a default sorting logic here
            break;
        }
        setTherapistList(sortedList);
      }
      const makePayment=async(i)=>{
        localStorage.setItem('selectedTherapist', JSON.stringify(i));
        localStorage.setItem('appoitObject', JSON.stringify(props?.item));
        localStorage.setItem('therapy_details', JSON.stringify(props?.item));
        const pt_d = await patientResponse(localStorage._patientKey);
        const appoitObject =props?.item
          let JsonData ={
            orderAmount: i.followup_price_max,
            customerName: pt_d.first_name+' '+pt_d.last_name,
            customerEmail: pt_d.email,
            customerPhone:pt_d.phone_number,
            dial_code: pt_d.dial_code,
            notify_url:'',
            source:'WEB',
            currency:i.currency,
            order_note:appoitObject?.prod?.title,
            returnUrl:RET_URL,
            };
        const res = await postapi('/create_paymentorder/',JsonData)
        console.log(res)
        if(res.status==='success'){
          payStatus(res,'Payment Initiated')
          localStorage.setItem('orderId',res.orderId)
          localStorage.setItem('purchase_type',"_N_T")
        }
       }
      const payStatus=async(resp,status)=>{
        const selectedTherapist = JSON.parse(localStorage.selectedTherapist);
        const localParam = JSON.parse(localStorage.appoitObject);
        console.log(localParam,selectedTherapist)
        const data= JSON.stringify({
          "patient_n_key":localStorage._patientKey,
          "app_payment_n_key": localParam?.app_payment_n_key,
          "total_session":1,
          "payment_status":status,
          "followup_amount":selectedTherapist?.followup_price_max,
          'addon':false,
          "followup_payment_details":resp.orderId,
          "followup_product_amount":selectedTherapist?.base_product_amount,
          "followup_discount":selectedTherapist?.followup_min_discount,
          "followup_discount_amount":selectedTherapist?.base_product_amount-selectedTherapist?.followup_price_max,
          "url":"",
          'followup_doctor':selectedTherapist?.employee_n_key,
          })
          const res = await postapi('/followup_payment/',data)
          console.log(res)
          if(res.status==='success'){
            const cf = new global.Cashfree(resp.payment_session_id)
            cf.redirect()
          }
      }
      const AdditionalInfo = ({ infoArray,showReadMore, setShowReadMore, Check }) => {
        const visibleItems = showReadMore ? infoArray : infoArray.slice(0, 3);
          return (
            <div className='d-flex align-items-center'>
              <p style={{ marginRight: 3 }}>
                {Check === 'speciality' ? <InfoOutlined style={{color:'#215352',fontSize:'16px'}} />
              : <FilterListOutlined style={{color:'#215352',fontSize:'16px'}} />  
              }
              </p>
              <div className='d-flex'>
                {visibleItems.map((item, index) => (
                  <p className='text-truncate' key={item} style={{fontSize:'12px'}}>
                    {item}
                    {index < visibleItems.length - 1 ? ', ' : ''}
                  </p>
                ))}
              </div>
              {infoArray?.length > 3 && (
                <button style={{fontSize:'12px',height:'12px',border:'none',backgroundColor:'white',textDecoration:'underline',color:'#3b7dbf'}} onClick={() => setShowReadMore(!showReadMore)}>
                  <p style={{}}>{showReadMore ? 'Read Less' : 'Read More'}</p>
                </button>
              )}
            </div>
          );
      };
      const handleClear = (stateSetter, dataArray,setKey) => {
        const updatedData = dataArray.map((item) => ({
          ...item,
          checked: false,
        }));
        setKey('')
        stateSetter(updatedData);
      };
      const handleSave = (stateSetter, dataArray) => {
        const filter = dataArray.filter((i)=>i.checked)[0]
        stateSetter(filter.name)
      };
      const handleChange = (stateSetter, dataArray, index,setKey) => {
        console.log(stateSetter, dataArray, index,setKey)
        const updatedData = dataArray.map((item, i) => ({
          ...item,
          checked: i === index ? !item.checked : false,
        }));
        const value=dataArray===experienceData?dataArray[index].value:dataArray[index].name
        setKey(value)
        stateSetter(updatedData);
      };
     const switchStep=(title)=>{
        switch (title) {
          case "Couple Therapy":
            return 3;
          case "Psychiatric Consultation":
            return 2;
          default:
            return 3;
        }
      }
      const onSetSidebarOpen = (open) => {
        setSidebarOpen(open);
      };
      const switchStepback=(title)=>{
        switch (title) {
          case "Couple Therapy":
            return 1;
          case "Psychiatric Consultation":
            return 0;
          default:
            return 1;
        }
      }
    const goBack = () => {
         Store.dispatch(
          ScheduleAction({
            scheduleStep: switchStepback(item?.title),
            scheduleCurrentStep: switchStepback(item?.title),
            problem:scheduleData.data?.problem,
            language:scheduleData?.data?.language,
            mode: scheduleData?.data?.mode,
            gender: scheduleData?.data?.gender,
            date: scheduleData?.data?.date,
            therapist: scheduleData?.data?.therapist,
           pt_key:scheduleData?.pt_key,
          })
        );
      };
  return (
    <Container className='col-md-12 px-1 px-md-1'>
      {loading?<Loader />:null}
      <div  className='mt-5 mt-md-2' />
      <div className='d-none d-md-block'>
      <button onClick={()=>{goBack()}} className="btn btn-sm mt-5 mt-md-1 border-none">
              <ArrowLeftCircle color="#215352" size={25} />
            </button>
      </div>
      
      <div className='customnavs d-block d-md-none mt-4  pr-n4'>
            <Sidebar
             sidebar={
               <div className="mt-4 pt-4 pl-2 pr-n4">
                <div className='d-flex pr-4 justify-content-between'>
              <button onClick={()=>setSidebarOpen(false)} style={{backgroundColor:'#fff',border:'none'}}><IoCloseSharp color='red' size={20} /> </button>
              <button onClick={()=>{setSidebarOpen(false);getTherapistProfile()}}  style={{backgroundColor:'#fff',border:'1px solid #215352'}} className='px-2 py-1 _text_color rounded' >Apply filters </button>
              </div>
                  <CheckboxSection 
                    sectionId="Availability" 
                    topLabel="Availability" 
                    options={availabilitydata}
                    handleChange={(index) => handleChange(setAvailabilityData, availabilitydata, index,setAvailability)}/>
                    
                    <CheckboxSection 
                     handleChange={(index) => handleChange(setExperienceData, experienceData, index,setExperience)}
                    sectionId="Experience" 
                    topLabel="Experience" 
                    options={experienceData}
                    />
                     
                     <CheckboxSection 
                    sectionId="Age Group" 
                    topLabel="Age Group" 
                    options={ageGroupData}
                    handleChange={(index) => handleChange(setAgeGroupData, ageGroupData, index,setAgeGroup)}
                    />
                     <CheckboxSection 
                    sectionId="Gender" 
                    topLabel="Gender" 
                    options={genderData}
                    handleChange={(index) => handleChange(setGenderData, genderData, index,setGender)}
                
                    />
                     <CheckboxSection 
                    sectionId="Language" 
                    topLabel="Language" 
                    options={languageData}
                    handleChange={(index) => handleChange(setLanguageData, languageData, index,setLanguage)}
                    />
                   {(item?.prod?.app_prod_grp_key === 'APP-PROD-CAT-1'||item?.app_prod_grp_key === 'APP-PROD-CAT-1')? <CheckboxSection 
                    sectionId="Mode" 
                    topLabel="Mode" 
                    options={modes}
                    handleChange={(index) => handleChange(setModes, modes, index,setMode)}
                    />:null}
                      <CheckboxSection 
                    sectionId="Modality" 
                    topLabel="Modality" 
                    options={modalitydata}
                    handleChange={(index) => handleChange(setModalityData, modalitydata, index,setModality)}
                    />
                      <CheckboxSection 
                    sectionId="Speciality" 
                    topLabel="Speciality" 
                    options={specialitydata}
                    handleChange={(index) => handleChange(setSpecialityData, specialitydata, index,setSpeciality)}
                    />
               </div>
             }
             pullRight={true}
             open={sidebarOpen }
             onSetOpen={onSetSidebarOpen}
             styles={{ sidebar: { width: '280px',backgroundColor:'#fff', } }}
              >
            {!sidebarOpen? <Container style={{zIndex:99}}>
              <div  className='mt-5 pt-2 d-flex justify-content-between'>
                <button style={{backgroundColor:'#f7f7f7',border:'none'}} onClick={()=>{goBack()}} className="border-none background-none">
                  <ArrowLeftCircle color="#215352" size={25} />
                </button>
                <button style={{backgroundColor:'#ddefe5',border:'1px solid #215352'}} className='px-2 _text_color py-1 rounded shadow-sm mr-5' onClick={()=>{setSidebarOpen(true)}}>
                   Custom filter<Filter className='pl-2' size={20} />
                </button>
              </div>
            </Container>:null}
            </Sidebar>
            </div>
            
            <Row  className="pt-md-2 pt-1">
                <Col className="col-md-12 " >
                  
      <div className="d-flex justify-content-between col-md-12 flex-wrap space-x-4 w-full py-2 px-2">
        <div className='d-none d-md-flex col-md-12 mx-auto justify-content-between '>
       {(item?.prod?.app_prod_grp_key === 'APP-PROD-CAT-1'||item?.app_prod_grp_key === 'APP-PROD-CAT-1')?
       <div className='border pt-1 px-2 rounded' style={{backgroundColor:'#ccc',cursor:'not-allowed'}}> {/* Adjust height as needed */}
       {scheduleData?.data?.problem?.product_title}
     </div>:null}
        <CustomCheckboxDropdown
          clear={() => handleClear(setAvailabilityData, availabilitydata,setAvailability)}
          save={()=>handleSave(setAvailability,availabilitydata)}
          title={availability||"Availability"}
          items={availabilitydata}
          handleChange={(index) => handleChange(setAvailabilityData, availabilitydata, index,setAvailability)}
        />
        <div className="border border-1" />
         <CustomCheckboxDropdown
          clear={() => handleClear(setExperienceData, experienceData,setExperience)}
          save={()=>handleSave(setExperience,experienceData)}
          title={experience||"Experience"}
          items={experienceData}
          handleChange={(index) => handleChange(setExperienceData, experienceData, index,setExperience)}
        />
         <CustomCheckboxDropdown
          clear={() => handleClear(setAgeGroupData, ageGroupData,setAgeGroup)}
          save={()=>handleSave(setAgeGroup,ageGroupData)}
          title={ageGroup||"Age Group"}
          items={ageGroupData}
          handleChange={(index) => handleChange(setAgeGroupData, ageGroupData, index,setAgeGroup)}
        />
         <CustomCheckboxDropdown
          clear={() => handleClear(setGenderData, genderData,setGender)}
          save={()=>handleSave(setGender,genderData)}
          title={gender||"Gender"}
          items={genderData}
          handleChange={(index) => handleChange(setGenderData, genderData, index,setGender)}
        />
        <CustomCheckboxDropdown
          clear={() => handleClear(setLanguageData, languageData,setLanguage)}
          save={()=>handleSave(setLanguage,languageData)}
          title={language||"Language"}
          items={languageData}
          handleChange={(index) => handleChange(setLanguageData, languageData, index,setLanguage)}
        />
        {(item?.prod?.app_prod_grp_key === 'APP-PROD-CAT-1'||item?.app_prod_grp_key === 'APP-PROD-CAT-1')?
          <CustomCheckboxDropdown
          clear={() => handleClear(setModes, modes,setMode)}
          save={()=>handleSave(setMode,modes)}
          title={mode||"Mode"}
          items={modes}
          handleChange={(index) => handleChange(setModes, modes, index,setMode)}
        />:null}
        <CustomCheckboxDropdown
          clear={() => handleClear(setSpecialityData, specialitydata,setSpeciality)}
          save={()=>handleSave(setSpeciality,specialitydata)}
          title={speciality||"Speciality"}
          items={specialitydata}
          handleChange={(index) => handleChange(setSpecialityData, specialitydata, index,setSpeciality)}
        />
        <button onClick={getTherapistProfile} className="bg_color_ rounded px-3 md:py-1  ml-1 text-white">
          <FaFunnelDollar fontSize={15} className="w-4 h-4 mr-2" />
          Filter
        </button>

          <select style={{border:'.4px solid #215352'}} onChange={(e)=>handleSort(e.target.value)} className="ml-2 rounded text_p_ _text_color px-2 bg-white">
              <option  value="">Sort by</option>
            <option value="1">Price low to high</option>
            <option value="2">Price high to low</option>
            <option value="3">Experience</option>
            <option value="4">Next available</option>
          </select>
          </div>
      </div>
                </Col>
            </Row>

            <Container className='mt-md-1 mt-5 pt-5 pt-md-1 px-0 px-md-2'>

            {(therapistList.length >0 && !suggested) ? <p className="pl-2 _text_p">Found <span className="h4">{therapistList.length}</span> providers aligned with your needs. Dive in to connect with the one who speaks to your journey.</p>:null}
      {suggested?<div className="d-flex text-left pl-2 mt-n2">
      <p style={{opacity:.7}} className="text-left pt-1 _text_p"><IoInformationCircleOutline size={20} color='red' /> We understand finding the right support is important to you, and we&apos;re here to help every step of the way. While we may not have the perfect match at this moment, rest assured, we&apos;re dedicated to connecting you with the right therapist soon.
      Meanwhile, explore these thoughtfully chosen alternatives to support your path to well-being. We&apos;re here to guide you to the right resources tailored to your needs.</p></div>:null} 
            <div className="d-flex flex-row flex-wrap px-0 mx-auto">
            {therapistList.length > 0 && therapistList.map((i) => (
              <div  key={i.employee_n_key} className="col-md-4 my-1 p-md-1 px-0">
                <div style={{backgroundColor:'#fff',borderColor:'#dbcda2'}} className='border rounded shadow-sm px-3 py-3 position-relative'>
                <div>
                { i.recommended !== 2 ? (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="tooltip">{i.recommended === 0 ?`Top experts known for deep empathy and care, safeguarding your mental well-being.`:`Specialists chosen for their ability to nurture tranquility and balance in your life.`}</Tooltip>}
                >
                  <div className="position-absolute" style={{ left: 0, top: 0, backgroundColor: '#dbb995' }}>
                    <span style={{ color: '#215352' }} className="header_font_ pl-3 pr-1 rounded">
                      {i.recommended === 0 ? `GuardianGems` : `ZenPicks`}
                    </span>
                    <InfoRounded color='#215352' style={{color:'#215352'}} fontSize='12px' className='pr-2' />
                  </div>
                </OverlayTrigger>
              ) : null }
                  <div className={`w-full ${i.recommended !== 2?'mt-4':'my-3'}`}>
                    <div className="d-flex">
                      <div className="">
                        <img src={i.profile_picture} style={{width:'75px',height:'75px',objectFit:'cover'}} className="rounded-circle" alt="Profile" />
                      </div>
                      <div className="d-flex flex-column ml-2">
                        <div className="d-flex justify-content-start">
                          <div>
                              <span style={{fontSize:'20px'}} className="ml-1 _text_color text-truncate header_font_ ">{(i.first_name+i.last_name).length>20?i.first_name:i.first_name + ' ' + i.last_name}</span>
                          </div>
                          <div className="d-flex flex-row justify-content-start">
                            {i.mode_of_telehealth.map((mode, index) => (
                              <div style={{ margin: '0 1px', alignSelf: 'center' }} key={index}>
                                {(() => {
                                  switch (mode) {
                                    case "Video":
                                      return <FaVideo className='ml-2' size={16} color='#215352' />
                                    case "Chat":
                                      return <ChatBubble style={{color:'#215352',fontSize:'16px'}} className='ml-2' />
                                    case "Audio":
                                      return <Phone size={16} color='#215352' className='ml-2' />
                                    default:
                                      return null;
                                  }
                                })()}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='d-flex flex-row justify-content-start'>
                        <span className="ml-1 text_p_">{i.role}</span>
                        <a target='_blank' href={`${SITE_URL}/provider/${i.employee_n_key}`}
                          className="text_p_ ml-2"
                          style={{textDecoration:'underline', color:'#3b7dbf',fontSize:'12px'}}
                        >
                          View full profile
                        </a>
                        </div>
                        <span className="ml-1 text_p_">{i.experience}</span>
                      </div>
                    </div>
                    <div className="languagesContainer pt-2">
                          <span><IoLanguage size={20} color='#215352' /></span>
                          {i.languages_known?.map((lang) => (
                            <span key={lang} className="text_p_">{lang}, </span>
                          ))}
                        </div>
                    <div className=''>
                      <span className='text_p_' ><ConfirmationNumber style={{color:'#215352',fontSize:'18px'}} /> Total number of completed sessions: </span>
                      <span style={{color:'#1c4169'}} className='text_p_ font-weight-bold'>{i.no_of_session_taken}</span>
                    </div>
                    <div>
                      <span className='text_p_'><TimerRounded style={{color:'#215352',fontSize:'18px'}} /> Duration of each session: </span>
                      <span  style={{color:'#1c4169'}} className='text_p_ font-weight-bold'>{i.session_minutes}</span>
                    </div>
                    {/* {i.speciality?.length > 0 && <AdditionalInfo infoArray={i.speciality || []} setShowReadMore={setShowReadMoreSpeciality} showReadMore={showReadMoreSpeciality} Check='speciality' key='speciality' />}
                    {i.therapy_style?.length > 0 && <AdditionalInfo infoArray={i.therapy_style || []} setShowReadMore={setShowReadMoreTherapyStyle} showReadMore={showReadMoreTherapyStyle} Check='therapy' key='therapy' />}  */}
                    <span className='text_p_'>
                      <span className='text_p_'><DateRange style={{color:'#215352',fontSize:'18px'}} /> Next available appointment slot: </span>
                      <span  style={{color:'#1c4169'}} className='text_p_ font-weight-bold'>{i.next_available}</span>
                    </span>
                  </div>
                </div>
                <div className="d-flex pt-2 align-items-center justify-content-between border-top py-1 mt-2">
                  <div className="">
                    <span className="_text_color text_p_ font-weight-bold">
                      Your next session price <br />
                      starts at: {getCurrencySymbol(i.currency)} {i.followup_price} - {getCurrencySymbol(i.currency)} {i.followup_price_max}
                    </span>
                  </div>
                  <button
                    onClick={async () => {
                      const appoitObject = props?.item;
                      if (appoitObject.switch) {
                        makePayment(i);
                      } else {
                        const filterData = concernData.filter((concern) => concern.name === concerns)[0];
                        const prblemData = {
                          app_prod_n_key: filterData?.value,
                          product_title: filterData?.name
                        };
                        let selectedProblem = (appoitObject?.prod?.app_prod_grp_key === 'APP-PROD-CAT-1'||item?.app_prod_grp_key === 'APP-PROD-CAT-1') ? prblemData : scheduleData?.data?.problem;
                          Store.dispatch(
                            ScheduleAction({
                              problem: selectedProblem,
                              mode: scheduleData?.data?.mode||'Video',
                              language:language,
                              therapist:i,
                              scheduleStep:switchStep(appoitObject?.title),
                              scheduleCurrentStep:switchStep(appoitObject?.title),
                              pt_key:scheduleData?.pt_key,
                            })
                          );
                      }
                    }}
                    className='btn btn-sm secondary_color_ _text_color font-weight-bold text_p_'
                    style={{height:'30px',backgroundColor:'#ddefe5',border:'.4px solid #215352'}}
                  >
                    Book now
                  </button>
                </div>
              </div>
              </div>
            ))}
          </div>
            </Container>
        </Container>
       
  )
}

const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        scheduleData: state.ScheduleData
    }
}

export default connect(mapStateToProps, null)(TherapistFilter);

