import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Tooltip, Progress, Modal } from "reactstrap";
import { ArrowLeft, ArrowRight, CheckCircle, Circle, Info } from "react-feather";
import { APP_URL, B_URL, CDN_URL, CURE_URL, REACT_APP_API_URL, REACT_APP_BASEURL } from "../../processENV";
import Store from "../../../Store";
import { LoginStateAction } from "../../../Actions/LoginAction";
import Loader from "../Loader";
import moment from "moment";
import { CloseSharp } from "react-ionicons";
import ip from "ip";
import OtpInput from "react-otp-input";
import { dialdata } from "../../../Helpers/dialdata";

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.monthFocus = React.createRef();
    this.yearFocus = React.createRef();
    this.state = {
      quesObj: [],
      newData: {},
      phoneavailable_: false,
      emailavailable_: false,
      emailuser_: false,
      businessemail_: false,
      businessphone_: false,
      otpmodal_: false,
      otp: "",
      use_comm:false,
      otpError: false,
      indx: 0,
      gender: [
        { label: "Male", value: "Male", checked: false },
        { label: "Female", value: "Female", checked: false },
        { label: "Non-Binary", value: "Non-Binary", checked: false },
        { label: "Transgender", value: "Transgender", checked: false },
        { label: "Something Else", value: "Something Else", checked: false },
        {
          label: "I choose Not to Disclose",
          value: "I choose Not to Disclose",
          checked: false,
        },
      ],
      maritalStaus: [
        { label: "Never Married", value: "Never Married", checked: false },
        { label: "Married", value: "Married", checked: false },
        { label: "Widowed", value: "Widowed", checked: false },
        { label: "Divorced", value: "Divorced", checked: false },
        { label: "Separated", value: "Separated", checked: false },
      ],
      occupation: [
        { label: "Agriculture", value: "Agriculture", checked: false },
        { label: "Artist", value: "Artist", checked: false },
        { label: "Human Resource", value: "Human Resource", checked: false },
        { label: "Engineer", value: "Engineer", checked: false },
        {
          label: "Software Developer",
          value: "Software Developer",
          checked: false,
        },
        { label: "Student", value: "Student", checked: false },
        { label: "Professor", value: "Professor", checked: false },
        { label: "Programmer", value: "Programmer", checked: false },
        { label: "Project Manager", value: "Project Manager", checked: false },
        { label: "Self-Employed", value: "Self-Employed", checked: false },
        { label: "Doctor", value: " Doctor", checked: false },
        { label: "Sales Executive", value: "Sales Executive", checked: false },
        { label: "Director", value: "Director", checked: false },
        { label: "Lawyer", value: "Lawyer", checked: false },
        { label: "Designer", value: "Designer", checked: false },
        {
          label: "Government Worker",
          value: "Government Worker",
          checked: false,
        },
        { label: "Welfare Worker", value: "Welfare Worker", checked: false },
        { label: "Pilot", value: "Pilot", checked: false },
        { label: "Others", value: "Others", checked: false },
      ],
      emailError: false,
      dateError: false,
      disable: true,
      date: "",
      month: "",
      year: "",
      tooltipOpen: false,
      name1: false,
      name2: false,
    };
  }

  componentDidMount() {
    console.log(this.props.location.state);
    var arr = this.props.location.state.pendingArray;
    this.setState({ quesObj: arr });
  }
  onInputChange = (e) => {
    console.log(e.target.value);
    let ob_ = this.state.newData;
    ob_.dial_code = e.target.value;
    this.setState({ newData: ob_, disable: false });
  };
  goPhone = async () => {
    console.log(this.props);
    this.setState({ emailavailable_: false });
    if (!this.state.businessemail_ && this.state.emailuser_) {
      Store.dispatch(
        LoginStateAction({
          step: 0,
          currentStep: 0,
          language: this.props.loginData.data.language,
          business: false,
          fromreg_: true,
        })
      );
      this.props.history.push("/", { language: "English", type: "individual" });
    } else {
      Store.dispatch(
        LoginStateAction({
          step: 0,
          currentStep: 0,
          language: this.props.loginData.data.language,
          business: true,
          email: this.state.newData.email,
          fromreg_: true,
        })
      );
      this.props.history.push("/", { language: "English", type: "employee" });
    }
  };
  handleChange = (otp) => this.setState({ otp });
  verifyOtp = async (e) => {
    e.preventDefault();

    const { newData } = this.state;
    let data = {
      phone_number: newData.phone_number,
      dial_code: newData.dial_code,
      email: newData.email,
      typedotp: this.state.otp,
    };
    fetch(REACT_APP_BASEURL + "api/business/login_otp/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == "success") {
          this.setState({ otpmodal_: false, disable: false });
        }
      });
  };
  continueBusiness = async () => {
    this.setState({ phoneavailable_: false });
    if (!this.state.businessphone_ && this.state.phoneuser_) {
      Store.dispatch(
        LoginStateAction({
          step: 0,
          currentStep: 0,
          language: this.props.loginData.data.language,
          business: false,
          fromreg_: true,
          mobileNumber: this.state.newData.phone_number,
          countryCode: this.state.newData.dial_code,
        })
      );
      this.props.history.push("/", { language: "English", type: "individual" });
    } else {
      const { newData } = this.state;
      let data = {
        phone_number: newData.phone_number,
        dial_code: newData.dial_code,
      };
      fetch(REACT_APP_BASEURL + "api/business/sendotp/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status == "success") {
            this.setState({ otpmodal_: true });
          }
        });
    }
  };
  continueQues = async () => {
    this.setState({
      phoneavailable_: false,
      businessphone_: false,
      phoneuser_: false,
      newData: { ...this.state.newData, phone_number: null },
    });
  };

  filteredData = (obj, arr) => {
    const result = {};
    arr.forEach((key) => {
        if (obj.hasOwnProperty(key)) {
            result[key] = obj[key];
        }
    });
    return result;
};
  submitQues = async () => {
    const { newData,quesObj } = this.state;
    console.log(newData);

    const keysToFilter = [...quesObj];
    if( this.props?.location?.state.email){
      keysToFilter.push('email')
    }
    else keysToFilter.push('patient_n_key')
    
    const datas = this.props.location.state.email
      ? {
          occupation: newData.occupation,
          date_of_birth: moment(newData.date_of_birth).format("YYYY-MM-DD"),
          first_name: newData.first_name,
          last_name: newData.last_name,
          gender: newData.gender,
          marital_status: newData.marital_status,
          email: newData.email,
          phone_number: newData.phone_number,
          email: this.props.location.state.email,
          dial_code: newData.dial_code,
          ip_address: ip.address(),
        }
      : {
          patient_n_key: localStorage._patientKey,
          occupation: newData.occupation,
          date_of_birth: moment(newData.date_of_birth).format("YYYY-MM-DD"),
          first_name: newData.first_name,
          last_name: newData.last_name,
          gender: newData.gender,
          dial_code: newData.dial_code,
          marital_status: newData.marital_status,
          email: newData.email,
          phone_number: newData.phone_number,
        };
        const data = this.filteredData(datas,keysToFilter)
    console.log(data);
    fetch(
      this.props.location.state.email
        ? REACT_APP_BASEURL + "api/business/bu_add_patient/"
        : `${REACT_APP_API_URL}/patientedit_app/`,
      {
        method: this.props.location.state.email ? "POST" : "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          this.handComm(res.data.patient_n_key)
          if (res.user_type == "Business") {
            let pk_ = res.data.patient_n_key.split("-")[2];
            let jwt_token = res.data.jwt_token;
            window.location.replace(
              `${B_URL}/${pk_}/${jwt_token}/auth/user`
            );
          } else window.location.href = "/home";
        }
      });
  };
  handComm = async (key) => {
      let data = {
        user_key: key,
        community_name: this.state.newData.first_name||'Anonymous',
        image: null,
      };
      fetch(`${CURE_URL}/community/community_user_update/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === "success") {
            console.log("community_name_Success");
          }
        });
  };
  handleKeyDown = (event) => {
    if (event.key === "Enter" && !this.state.disable) {
      if (this.state.indx + 1 == this.state.quesObj.length) {
        this.submitQues();
      } else this.setState({ indx: this.state.indx + 1, disable: true });
    }
  };
  handleQues = async (text, key, val) => {
    if (key == "first_name") {
      if (text.trim().length >= 2) {
        this.setState({
          newData: { ...this.state.newData, first_name: text },
          disable: false,
          name1: false,
        });
      } else {
        this.setState({ disable: true, name1: true });
      }
      console.log(this.state.quesObj, this.state.disable);
    }
    if (key == "last_name") {
      if (text.trim().length >= 2) {
        this.setState({
          newData: { ...this.state.newData, last_name: text },
          disable: false,
          name2: false,
        });
      } else {
        this.setState({ disable: true, name2: true });
      }
    }
    if (key == "phone_number") {
      if (
        this.state.newData.dial_code &&
        this.state.newData.dial_code == "+91"
      ) {
        if (text.trim().length == 10) {
          fetch(REACT_APP_BASEURL + "api/business/check_business_phone/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify({ phone_number: text }),
          })
            .then((response) => response.json())
            .then((res) => {
              console.log("duplicatephoins", res);
              if (res.status == "success") {
                this.setState({
                  newData: { ...this.state.newData, phone_number: text },
                  disable: false,
                });
              } else {
                let dk_ = { ...this.state.newData };
                dk_.phone_number = text;
                if (res.patient) {
                  this.setState({ phoneuser_: true, newData: dk_ });
                }
                if (res.bu_org_id) {
                  this.setState({ businessphone_: true, newData: dk_ });
                }
                this.setState({ phoneavailable_: true, newData: dk_ });
              }
            });
        } else {
          this.setState({ disable: true });
        }
      } else {
        if (text.trim().length >= 2) {
          fetch(REACT_APP_BASEURL + "api/business/check_business_phone/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify({ phone_number: text }),
          })
            .then((response) => response.json())
            .then((res) => {
              console.log("duplicatephoins", res);
              if (res.status == "success") {
                this.setState({
                  newData: { ...this.state.newData, phone_number: text },
                  disable: false,
                });
              } else {
                if (res.patient) {
                  this.setState({ phoneuser_: true });
                }
                if (res.bu_org_id) {
                  this.setState({ businessphone_: true });
                }
                this.setState({
                  phoneavailable_: true,
                  newData: { ...this.state.newData, phone_number: text },
                });
              }
            });
        } else {
          this.setState({ disable: true });
        }
      }
    }
    if (key == "email") {
      let pattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(text)) {
        fetch(REACT_APP_BASEURL + "api/business/check_business_mail/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify({ email: text }),
        })
          .then((response) => response.json())
          .then((res) => {
            console.log("duplicate", res);
            if (res.status == "success") {
              this.setState({
                newData: { ...this.state.newData, email: text },
                disable: false,
                emailError: false,
              });
            } else {
              let i = { ...this.state.newData };
              i.email = text;
              if (res.patient) {
                this.setState({ emailuser_: true });
              }
              if (res.bu_org_id) {
                this.setState({ businessemail_: true });
              }
              this.setState({
                emailavailable_: true,
                disable: true,
                newData: i,
              });
            }
          });
      } else {
        this.setState({ disable: true, emailError: true });
      }
    }
    if (key == "date_of_birth") {
      var nD = new Date();
      var Yr = nD.getFullYear();

      if (text >= Yr) {
        this.setState({ dateError: true, disable: true });
      } else if (!this.state.month || !this.state.date) {
        this.setState({ dateError: true, disable: true });
      } else {
        var DOB = this.state.month + "/" + this.state.date + "/" + text;
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var age = currentYear - text;
        if (age <= 15) {
          this.setState({ disable: true });
        } else {
          this.setState({
            newData: {
              ...this.state.newData,
              date_of_birth: moment(DOB).toDate(),
            },
            disable: false,
            ageError: "",
          });
          console.log(this.state);
        }
      }
    }
    if (key == "pincode") {
      if (text.trim().length >= 2) {
        this.setState({
          newData: { ...this.state.newData, pincode: text },
          disable: false,
        });
      } else {
        this.setState({ disable: true });
      }
    }
  };
  handleRadio = (val, ind) => {
    var arr = this.state.gender.map((i, indx) => {
      if (indx === ind) {
        return { ...i, checked: true };
      }
      return { ...i, checked: false };
    });
    this.setState(
      {
        gender: arr,
        newData: { ...this.state.newData, gender: val },
        disable: false,
      },
      () => {
        if (this.state.quesObj.length - 1 === this.state.indx) {
          console.log("yesds");
        } else {
          setTimeout(
            () =>
              this.setState({
                indx: this.state.indx + 1,
                disable: true,
              }),
            100
          );
        }
      }
    );
  };
  handleRadios = (val, ind) => {
    console.log(this.state.quesObj);
    var arr = this.state.maritalStaus.map((i, indx) => {
      if (indx === ind) {
        return { ...i, checked: true };
      }
      return { ...i, checked: false };
    });
    this.setState(
      {
        maritalStaus: arr,
        newData: { ...this.state.newData, marital_status: val },
        disable: false,
      },
      () => {
        if (this.state.quesObj.length - 1 === this.state.indx) {
          console.log("yesds");
        } else {
          setTimeout(
            () =>
              this.setState({
                indx: this.state.indx + 1,
                disable: true,
              }),
            100
          );
        }
      }
    );
  };
  handleCheck = (val, ind) => {
    console.log(val);
    var arr = this.state.occupation.map((i, indx) => {
      if (indx === ind) {
        return { ...i, checked: true };
      }
      return { ...i, checked: false };
    });
    this.setState({
      occupation: arr,
      newData: { ...this.state.newData, occupation: val },
      disable: false,
    });
  };
  handleSubmit = async () => {
    console.log(this.state.newData);
  };
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  checkDOB = async () => {
    console.log(this.state.newData.date_of_birth);
    if (this.state.newData.date_of_birth) {
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var age = currentYear - this.state.year;
      if (this.state.date && this.state.date > 31) {
        this.setState({ disable: true });
      }
      this.setState({ disable: false });
      if (this.state.month && this.state.month > 12) {
        this.setState({ disable: true });
      }
      this.setState({ disable: false });
      if (age <= 15) {
        this.setState({ disable: true });
      } else {
        this.setState({ disable: false });
      }
    } else {
      this.setState({ disable: true });
    }
  };

  render() {
    const {
      quesObj,
      indx,
      gender,
      maritalStaus,
      disable,
      newData,
      occupation,
      phoneavailable_,
      phoneuser_,
      emailuser_,
      emailavailable_,
      use_comm,
    } = this.state;
    return (
      <>
        <Modal centered isOpen={emailavailable_}>
          <div className="p-4 ">
            <div className="text-right">
              <button
                className="btn"
                onClick={() => {
                  let i = { ...newData };
                  i.email = null;
                  this.setState({
                    emailavailable_: false,
                    newData: i,
                  });
                }}
              >
                <CloseSharp color="#215352" size={25} />
              </button>
            </div>
            <p className="text_p_ mt-md-4">
              {this.state.businessemail_ && this.state.emailuser_
                ? `Sorry, the email you entered is already registered as a business account. Please enter another email address`
                : this.state.businessemail_ && !this.state.emailuser_
                ? `Sorry, the email you entered is already registered as a business account. Please enter another email address`
                : `Sorry, the email you entered is already registered with us as an individual user. Please enter another mobile number.`}
            </p>
            <div className="mx-auto text-center">
              <button
                style={{ background: "#215352" }}
                className="border rounded btn text-center p-2 text-white"
                onClick={() => {
                  let i = { ...newData };
                  i.email = null;
                  this.setState({
                    emailavailable_: false,
                    newData: i,
                    emailuser_: false,
                    businessemail_: false,
                  });
                }}
              >
                Enter new email
              </button>
            </div>
            <p className="text-center mx-auto header_font_ mt-4">[OR]</p>
            <div className="mx-auto text-center">
              <button
                style={{ background: "#215352" }}
                className="mx-auto btn text-center p-2 text-white"
                onClick={() => {
                  this.goPhone();
                }}
              >
                {this.state.businessemail_ && this.state.emailuser_
                  ? `Continue as business user`
                  : this.state.businessemail_ && !this.state.emailuser_
                  ? `Continue as business user`
                  : `Log in to your account`}
              </button>
            </div>
          </div>
        </Modal>
        <Modal className="p-0" centered isOpen={this.state.otpmodal_}>
          <div
            style={{ background: "#215352" }}
            className="choose-language-outer p-0"
          >
            <div className="d-flex">
              <Col md={10} className="mx-auto">
                <div className="choose-language-inner">
                  <div className="otp_sec">
                    <form onSubmit={this.verifyOtp}>
                      <div className="mx-auto col-md-10 reactinput mt-4 ">
                        <OtpInput
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={6}
                          separator={<span> </span>}
                        />
                      </div>
                      <h4 className="mt-md-4 h5 mt-3 text-white">
                        Your code may take up to a minute to arrive.
                        <span onClick={() => this.continueBusiness()}>
                          Send New Code!
                        </span>
                      </h4>
                      <div className="text-center">
                        <button
                          style={{ background: "#fff", color: "#215352" }}
                          type="submit"
                          className="btn w-90 text-center mx-auto my-5"
                        >
                          {" "}
                          Verify{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </Modal>
        <Modal isOpen={phoneavailable_}>
          <div className="p-4 ">
            <div className="text-right">
              <button
                onClick={() => {
                  this.setState({ phoneavailable_: false, disable: true });
                }}
                className="btn"
              >
                <CloseSharp color="#215352" size={25} />
              </button>
            </div>
            <p className="text_p_ mt-md-4">
              {this.state.businessphone_ && this.state.phoneuser_
                ? `Sorry, the mobile number you entered is already registered as a business account. Please enter another mobile number`
                : this.state.businessphone_ && !this.state.phoneuser_
                ? `Sorry, the mobile number you entered is already registered as a business account. Please enter another mobile number`
                : `Sorry, the mobile number you entered is already registered with us as an individual user. Please enter another mobile number.`}
            </p>
            <div className="mx-auto text-center">
              <button
                style={{ background: "#215352" }}
                className="border rounded btn text-center p-2 text-white"
                onClick={() => this.continueQues()}
              >
                Enter new mobile number
              </button>
            </div>
            <p className="text-center mx-auto header_font_ mt-4">[OR]</p>
            <div className="mx-auto text-center">
              <button
                style={{ background: "#215352" }}
                className="border rounded btn text-center p-2 text-white"
                onClick={() => {
                  this.continueBusiness();
                }}
              >
                {this.state.businessphone_ && this.state.phoneuser_
                  ? `Continue as business user`
                  : this.state.businessphone_ && !this.state.phoneuser_
                  ? `Continue as business user`
                  : `Log in to your account`}
              </button>
            </div>
            <span className="_text_p _text_color mt-2">
              ⚠️ You need to verify your mobile number to continue as a business
              user.
            </span>
          </div>
        </Modal>

        <div
          className="col-md-12 mt-0 d-flex flex-row p-0"
          style={{ height: "100%", background: "#F6E1BC" }}
        >
          <div className="mt-md-6 col-md-3  d-none d-lg-block">
            <img
              className="img-fluid"
              style={{ maxWidth: "280px" }}
              src={`${CDN_URL}/CaremeLogo/finallogo1copy.png`}
            />
          </div>
          <div className="register_form col-md-9 position-relative">
            {quesObj ? (
              <div>
                <Progress
                  className="mt-3"
                  value={`${((indx + 1) / quesObj.length) * 100}`}
                  color="dark"
                />
                {indx !== 0 ? (
                  <button
                    className="d-flex mt-md-3 btn btn-secondary text_p_ text-white font-weight-bold"
                    onClick={() => this.setState({ indx: indx - 1 })}
                  >
                    {" "}
                    <ArrowLeft
                      color="#fff"
                      size={24}
                      onClick={() => this.setState({ indx: indx - 1 })}
                    />
                    back{" "}
                  </button>
                ) : null}
                {quesObj[indx] === "first_name" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_ ml-0"> How can we call you?</h3>
                    <input
                      type="text"
                      onKeyDown={this.handleKeyDown}
                      autoFocus
                      onFocus={
                        newData.first_name
                          ? () => {
                              this.setState({ disable: false });
                            }
                          : null
                      }
                      placeholder="First Name"
                      defaultValue={newData.first_name}
                      className="first_name__ text_p_ col-md-5 form-control mt-md-3 my-2 p-4"
                      onChange={(e) =>
                        this.handleQues(e.target.value, "first_name")
                      }
                    />
                    <button 
                    onClick={()=>{this.setState({use_comm:!use_comm})}}
                    style={{backgroundColor:use_comm?'#215352':'#fff',color:use_comm?'#fff':'#215352', border:'.3px solid #215352'}}
                    className="text_p_ col-md-3 d-flex align-items-center mt-md-3 my-2 rounded py-2">
                      {use_comm? <CheckCircle className="mr-2" size={18} color="#ffff" /> :<Circle size={18} className="mr-2" color="#215352" />}
                      Use this name for community
                    </button>
                    {this.state.name1 && (
                      <span style={{top:'25%'}} className="font-weight-bold text_p_ mt-md-5 mt-5 pt-md-4 pt-5">
                        FirstName should be atleast 2 characters
                      </span>
                    )}
                  </div>
                )}
                {quesObj[indx] === "last_name" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_"> How can we call you?</h3>
                    <input
                      type="text"
                      onKeyDown={this.handleKeyDown}
                      autoFocus
                      onFocus={
                        newData.last_name
                          ? () => {
                              this.setState({ disable: false });
                            }
                          : null
                      }
                      placeholder="Last Name"
                      defaultValue={newData.last_name}
                      className="last_name__ text_p_ col-md-5 form-control mt-md-3 my-2 p-4"
                      onChange={(e) =>
                        this.handleQues(e.target.value, "last_name")
                      }
                    />
                    {this.state.name2 && (
                      <span className="font-weight-bold text_p_  mt-md-5 mt-5 pt-md-4 pt-5">
                        LastName should be atleast 2 characters
                      </span>
                    )}
                  </div>
                )}
                {quesObj[indx] === "phone_number" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_">
                      What's Your Contact Number?
                    </h3>
                    <input
                      type="number"
                      onKeyDown={this.handleKeyDown}
                      autoFocus
                      onFocus={
                        newData.phone_number
                          ? () => {
                              this.setState({ disable: false });
                            }
                          : null
                      }
                      placeholder="Phone number"
                      className="phone_number__ text_p_ col-md-5 form-control mt-md-3 mt-2 p-4"
                      defaultValue={newData.phone_number}
                      onChange={(e) =>
                        this.handleQues(e.target.value, "phone_number")
                      }
                    />
                  </div>
                )}
                {quesObj[indx] === "email" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_">Give us your email! </h3>
                    <input
                      type="text"
                      onKeyDown={this.handleKeyDown}
                      autoFocus
                      onFocus={
                        newData.email
                          ? () => {
                              this.setState({ disable: false });
                            }
                          : null
                      }
                      placeholder="Email Address"
                      defaultValue={newData.email}
                      className="email_input__ text_p_ col-md-5 form-control mt-md-3 my-2 p-4"
                      onChange={(e) => this.handleQues(e.target.value, "email")}
                    />
                    {this.state.emailError && (
                      <span className="font-weight-bold text_p_ mt-md-5 mt-5 pt-md-4 pt-5">
                        Enter a valid email address
                      </span>
                    )}
                  </div>
                )}
                {quesObj[indx] === "dial_code" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_">
                      What's the dial code for your location?
                    </h3>
                    <select
                      onKeyDown={this.handleKeyDown}
                      style={{ height: "50px", fontSize: "20px" }}
                      name="countryCode"
                      className={
                        "text_p_ col-md-5 form-control mt-md-3 my-2 p-0"
                      }
                      value={this.state.newData.dial_code || ""}
                      placeholder="+91"
                      onChange={(e) => {
                        this.onInputChange(e);
                      }}
                    >
                      <option value="">-- Select --</option>
                      {dialdata &&
                        dialdata.map((item, i) => {
                          return (
                            <option value={item.code}>
                              {" "}
                              {item.code + " " + item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}

                {quesObj[indx] === "date_of_birth" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_">What's your DOB?</h3>
                    <div className="d-flex flex-row my-2">
                      <input
                        type="number"
                        maxLength="2"
                        defaultValue={this.state.date}
                        autoFocus
                        onFocus={() => this.checkDOB()}
                        placeholder="DD"
                        className="date_input__ text_p_ col-md-2 form-control mt-md-3 mt-2 p-4"
                        onChange={(e) => {
                          if (e.target.value > 31 || e.target.value == 0) {
                            this.setState({ dateError: true, disable: true });
                          } else {
                            this.setState(
                              { date: e.target.value, dateError: false },
                              () =>
                                e.target.value.trim().length == 2
                                  ? this.monthFocus.current.focus()
                                  : null
                            );
                          }
                        }}
                      />
                      <input
                        type="number"
                        maxLength="2"
                        ref={this.monthFocus}
                        defaultValue={this.state.month}
                        onFocus={() => this.checkDOB()}
                        placeholder="MM"
                        className="month_input__ text_p_ col-md-2 form-control mt-md-3 mt-2 p-4"
                        onChange={(e) => {
                          if (e.target.value > 12 || e.target.value == 0) {
                            this.setState({ dateError: true, disable: true });
                          } else {
                            this.setState(
                              { month: e.target.value, dateError: false },
                              () =>
                                e.target.value.trim().length == 2
                                  ? this.yearFocus.current.focus()
                                  : null
                            );
                          }
                        }}
                      />
                      <input
                        type="number"
                        onKeyDown={this.handleKeyDown}
                        ref={this.yearFocus}
                        defaultValue={this.state.year}
                        onFocus={() => this.checkDOB()}
                        maxLength={4}
                        placeholder="YYYY"
                        className="year_input__ text_p_ col-md-2 form-control mt-md-3 mt-2 p-4"
                        onChange={(e) => {
                          if (
                            e.target.value < 1900 ||
                            e.target.value.trim().length < 4
                          ) {
                            this.setState({ dateError: true, disable: true });
                          } else {
                            this.setState({
                              year: e.target.value,
                              dateError: false,
                            });
                            if (e.target.value.trim().length >= 4)
                              this.handleQues(e.target.value, "date_of_birth");
                          }
                        }}
                      />
                    </div>
                    {this.state.dateError && (
                      <span
                        className="font-weight-bold text_p_ mt-md-5 mt-5 pt-md-4 pt-5"
                        style={{ marginTop: 80 }}
                      >
                        Enter a valid date
                      </span>
                    )}
                  </div>
                )}
                {quesObj[indx] === "gender" && (
                  <div className="input_reg_div pt-2 px-2 pt-md-5">
                    <div className="position-relative">
                      <h3 className="header_font_">
                        {" "}
                        What is your biological sex?{" "}
                      </h3>
                      <span
                        className="my-3 position-absolute"
                        href="#"
                        id="TooltipExample"
                        style={{
                          fontSize: 15,
                          marginLeft: 0,
                          top: 40,
                          color: "#000",
                        }}
                      >
                        Why we're asking <Info color="#000" size={20} />{" "}
                      </span>
                      <Tooltip
                        placement="right"
                        style={{ color: "#fff" }}
                        isOpen={this.state.tooltipOpen}
                        target="TooltipExample"
                        toggle={this.toggle}
                      >
                        We need to know your biological sex for medical purposes
                        only, to help us provide the best care possible. We
                        understand your gender identity may be different.
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-column flex-wrap mt-md-5 pt-md-3 mt-4 px-1 px-md-0 mx-4 mx-md-0">
                      {gender.map((i, indx) => {
                        return (
                          <button
                            onClick={() => this.handleRadio(i.value, indx)}
                            className={`${
                              i.checked ? "_reg_radio_" : "reg_radio__"
                            } col-md-3 p-2 rounded my-2 text_p_ font-weight-bold`}
                          >
                            {i.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
                {quesObj[indx] === "marital_status" && (
                  <div className="input_reg_div pt-2 px-2 pt-md-5">
                    <h3
                      className="header_font_"
                    >
                      What's Your Marital Status?{" "}
                    </h3>
                    <div className="d-flex flex-column flex-wrap mt-md-4 px-1 px-md-0 mx-4 mx-md-0">
                      {maritalStaus.map((i, indx) => {
                        return (
                          <button
                            onClick={() => this.handleRadios(i.value, indx)}
                            className={`${
                              i.checked ? "_reg_radio_" : "reg_radio__"
                            } col-md-3 p-2 rounded my-2 text_p_ font-weight-bold`}
                          >
                            {i.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
                {quesObj[indx] === "occupation" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_">What's Your occupation? </h3>
                    <div className="d-flex flex-column flex-wrap mt-md-4">
                      {occupation.map((i, indx) => {
                        return (
                          <button
                            onClick={() => this.handleCheck(i.value, indx)}
                            className={`${
                              i.checked ? "_reg_radio_" : "reg_radio__"
                            } col-md-3 p-2 rounded my-2 text_p_ font-weight-bold`}
                          >
                            {i.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
                {quesObj[indx] === "pincode" && (
                  <div className="input_reg_div pt-5 px-2 pt-md-5">
                    <h3 className="header_font_">Enter Your Pincode!</h3>
                    <input
                      type="number"
                      onKeyDown={this.handleKeyDown}
                      defaultValue={newData.pincode}
                      autoFocus
                      onFocus={
                        newData.pincode
                          ? () => {
                              this.setState({ disable: false });
                            }
                          : null
                      }
                      placeholder="pincode"
                      className="pincode_input__ text_p_"
                      onChange={(e) =>
                        this.handleQues(e.target.value, "pincode")
                      }
                    />
                  </div>
                )}

                <div className="reg_footer position-absolute">
                  {quesObj.length - 1 == indx ? (
                    <button
                      className="btn px-4 py-2 text_p_ text-white font-weight-bold mt-3"
                      disabled={
                        quesObj[indx] == "gender" && newData.gender
                          ? false
                          : quesObj[indx] == "marital_status" &&
                            newData.marital_status
                          ? false
                          : disable
                      }
                      style={{
                        opacity:
                          quesObj[indx] == "gender" && newData.gender
                            ? 1
                            : quesObj[indx] == "marital_status" &&
                              newData.marital_status
                            ? 1
                            : disable
                            ? 0.6
                            : 1,
                      }}
                      onClick={() => this.submitQues()}
                    >
                      Continue <ArrowRight />{" "}
                    </button>
                  ) : (quesObj[indx] !== "marital_status" && quesObj[indx] !== "gender"&&
                    <button
                      className="btn px-4 py-2 text_p_ text-white font-weight-bold mt-3"
                      disabled={
                        quesObj[indx] == "gender" && newData.gender
                          ? false
                          : quesObj[indx] == "marital_status" &&
                            newData.marital_status
                          ? false
                          : disable
                      }
                      style={{
                        opacity:
                          quesObj[indx] == "gender" && newData.gender
                            ? 1
                            : quesObj[indx] == "marital_status" &&
                              newData.marital_status
                            ? 1
                            : disable
                            ? 0.6
                            : 1,
                      }}
                      onClick={() => {
                        if (this.state.quesObj.length - 1 === this.state.indx) {
                          console.log("Yes");
                        } else {
                          this.setState({
                            indx: indx + 1,
                            disable: true,
                          });
                        }
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(withRouter(RegistrationForm));
