import React from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import {
  BsCheck2Circle,
  BsCircle
} from "react-icons/bs";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  Col
} from "reactstrap";
import { ScheduleAction } from "../../Actions/ScheduleAction";
import { postapi } from "../../Helpers/Api";
import Store from "../../Store";
import { failed, success } from "../../assets/images";

class PaymentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      _fol_therapist: false,
      creditModal: false,
      old: false,
      new: false,
      empDetails: [],
      selectP: "",
    };
  }
  componentDidMount() {
    this.getStatus()
  }
  getStatus=async()=>{
    let orderId = localStorage.getItem("orderId");
    this.setState({orderId:orderId });
    let data = {orderId: orderId};
    const purchase_type = localStorage.getItem("purchase_type");
    const res = await postapi('/get_paymentstatus/',data)
        if (res.status === "success") {
          this.setState({ paymentStatus: res.data }, () => {
            if (res.data?.order_status === "PAID") {
              if (purchase_type === "APPOINTMENT"||purchase_type==='_N_T') {
                this.paidSuccess();
              }
             else if (purchase_type === "COACH") {
                this.paidSuccesschat();
              }
            } else {
              if (purchase_type === "APPOINTMENT"||purchase_type==='_N_T') {
                this.payFailed();
              }
              else if (purchase_type === "COACH") {
                this.payFailedchat();
              }
            }
          });
        } else {
          this.setState({
            loading: false,
            error: "Some error has occured please try again",
          });
        }
  }
  payFailedchat = async () => {
    const item = JSON.parse(localStorage._cc);
    const selected_plan = item._ps;
    const product = item._p;
    const data = {
      patient_n_key: localStorage._patientKey,
      app_payment_n_key: localStorage._ks,
      total_session: selected_plan.no_of_session||1,
      payment_status: "Payment Failed",
      followup_amount: selected_plan.amount,
      payment_details: localStorage.orderId,
      product_amount: selected_plan.product_amount,
      discount: selected_plan.discount,
      discount_amount: selected_plan.discount_amount,
      product: product[0]?.product_title,
      app_prod_n_key: product[0]?.app_prod_n_key,
    };
    const res = await postapi('/cashfree_payment/',data)
    console.log(res);
  };
  paidSuccesschat = async () => {
    const item = JSON.parse(localStorage._cc);
    const selected_plan = item?._ps;
    const product = item?._p;
    const data = {
      patient_n_key: localStorage._patientKey,
      app_payment_n_key: localStorage._ks,
      total_session: selected_plan?.no_of_session||1,
      payment_status: "Payment Success",
      followup_amount: selected_plan?.amount,
      payment_details: localStorage.orderId,
      product_amount: selected_plan?.product_amount,
      discount: selected_plan?.discount,
      discount_amount: selected_plan?.discount_amount,
      product: product[0]?.product_title,
      app_prod_n_key: product[0]?.app_prod_n_key,
    };
    const res = await postapi('/cashfree_payment/',data)
        if (res.status == "success") {
          var Jsondata = {
            patient_n_key: localStorage._patientKey,
            total_session: selected_plan?.no_of_session,
            app_payment_n_key: localStorage._ks,
          };
          const resp = await postapi('/update_counselling/',Jsondata)
              if (resp.status == "success") {
                localStorage._ks=''
                this.props.history.push("/chatdetails");
              }
        }
  };
  payFailed = async () => {
    let item = JSON.parse(localStorage.therapy_details);
    const data = {
      patient_n_key: localStorage._patientKey,
      app_payment_n_key: item.app_payment_n_key,
      total_session: item.total_session,
      payment_status: "Payment Failed",
      followup_amount: item.amount,
      addon: item.follow_adon,
      followup_payment_details: localStorage.orderId,
      followup_product_amount: item.product_amount,
      followup_discount: item.followup_discount,
      followup_discount_amount: item.discount_amount,
      url: "",
      followup_doctor: item.doc_app_id,
    };
    console.log(data);
    const res = await postapi('/followup_payment/',data)
    console.log(res);
  };
  paidSuccess = async (id, amount) => {
    let datatherapy = JSON.parse(localStorage.therapy_details);
    const data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      app_payment_n_key: datatherapy.app_payment_n_key,
      total_credit: datatherapy.total_session,
      total_session: datatherapy.total_session,
      payment_status: "Payment Success",
      addon: datatherapy.follow_addon,
      followup_payment_details: localStorage.orderId,
      payment_details: localStorage.orderId,
      followup_product_amount: datatherapy.product_amount,
      product_amount: datatherapy.product_amount,
      followup_discount: datatherapy.discount,
      discount: datatherapy.discount,
      followup_discount_amount: datatherapy.discount_amount,
      discount_amount: datatherapy.discount_amount,
      followup_doctor: datatherapy.doc_app_id,
      app_prod_n_key: datatherapy.app_prod_n_key,
      paid_amount: datatherapy.amount,
      amount: datatherapy.amount,
      url: "",
      app_prod_grp_key: datatherapy.app_prod_grp_key,
      journey_id: datatherapy.journey_id,
      current_amount: datatherapy.amount,
      current_payment_details:localStorage.orderId,
      promo_code:datatherapy.coupon,
      current_product_amount:datatherapy.product_amount,
      current_discount:datatherapy.discount,
      current_discount_amount:datatherapy.discount_amount,
      employee_n_key:datatherapy.doc_app_id, 
    };
    console.log(data);
    const resp = await postapi('/followup_payment/',data)
    console.log(resp)
    const res = await postapi('/add_sessionpay/',data)
        console.log(res);
        if (res.status == "success") {
          let jsonData = {
            patient_n_key: localStorage._patientKey,
            app_prod_grp_key: datatherapy.app_prod_grp_key,
          };
          const reponse = await postapi('/previous_check/',jsonData)
              console.log("cancel appointment", res.data);
              if (reponse.status == "success") {
                if (reponse.data[0].is_active == 1) {
                  this.setState({ _fol_therapist: false });
                  let appointmentDetail = JSON.stringify({
                    doc_app_id: reponse.data[0].employee_n_key,
                    app_prod_grp_key: datatherapy.app_prod_grp_key,
                    app_payment_n_key: datatherapy.app_payment_n_key,
                  });
                  localStorage.setItem("therapy_details", appointmentDetail);
                  this.props.history.push("therapyjourney/selectSession/", {
                    action: "Date",
                  });
                } else {
                  this.props?.history?.push('creditsleft',{
                    selectP: datatherapy,
                    creditModal: true,
                    empDetails: reponse.data,
                    _fol_therapist: true,
                  });
                }
              }
        }

  };
  appointDoctor = async(pdata) => {
    let getCareData = JSON.parse(localStorage.getItem("appoint_details"));
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      appointment_time: getCareData?.time,
      appointment_date: getCareData?.date,
      preferred_language: getCareData?.language,
      sale_price: pdata.orderAmount,
      tax: "0",
      tax_amount: "0",
      status: "Paid",
      product: getCareData?.product,
      paid_amount: pdata.orderAmount,
      app_prod_n_key: getCareData?.prodId,
      endtime: getCareData?.endTime,
      product_amount: getCareData?.amount,
      total_session: getCareData?.no_of_session,
      discount: getCareData?.discount,
      discount_amount: getCareData?.discount_amount,
      app_payment_n_key: localStorage.getItem("app_payment_n_key"),
    };
    const res = await postapi('/appoint_doctor/',data)
        console.log("payment status", res);
        this.setState({ appointment: res.data});
        if (res.status === "success") {
          this.updatePaymentStatus("Completed");
        } else {
          this.setState({
            loading: false,
            error: "Some error has occured please try again",
          });
        }
  };
  updatePaymentStatus = async(status) => {
    let data = {
      payment_status: status,
      app_payment_n_key: localStorage.getItem("app_payment_n_key"),
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    const res = await postapi('/cashfree_payment/',data)
        if (res.status === "success") {
          this.props.history.push("therapyjourney", { item: "Followup" });
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
  };
  changepayFailed = async () => {
    let item = JSON.parse(localStorage.therapy_details);
    const data = {
      patient_n_key: localStorage._patientKey,
      app_payment_n_key: item.app_payment_n_key,
      total_session: item.total_session,
      payment_status: "Payment Failed",
      followup_amount: item.amount,
      addon: item.follow_adon,
      followup_payment_details: localStorage.orderId,
      followup_product_amount: item.product_amount,
      followup_discount: item.followup_discount,
      followup_discount_amount: item.discount_amount,
      url: "",
      followup_doctor: item.doc_app_id,
    };
    console.log(data);
    const res = await postapi('/followup_payment/',data)
    console.log(res);
  };
  changepaidSuccess = async (id, amount) => {
    let datatherapy = JSON.parse(localStorage.therapy_details);
    const data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      app_payment_n_key: datatherapy.app_payment_n_key,
      total_credit: datatherapy.total_session,
      total_session: datatherapy.total_session,
      payment_status: "Payment Success",
      addon: datatherapy.follow_addon,
      followup_payment_details: localStorage.orderId,
      payment_details: localStorage.orderId,
      followup_product_amount: datatherapy.product_amount,
      product_amount: datatherapy.product_amount,
      followup_discount: datatherapy.discount,
      discount: datatherapy.discount,
      followup_discount_amount: datatherapy.discount_amount,
      discount_amount: datatherapy.discount_amount,
      followup_doctor: datatherapy.doc_app_id,
      app_prod_n_key: datatherapy.app_prod_n_key,
      paid_amount: datatherapy.amount,
      amount: datatherapy.amount,
      url: "",
      app_prod_grp_key: datatherapy.app_prod_grp_key,
      journey_id: datatherapy.journey_id,
      current_amount: datatherapy.amount,
      current_payment_details:localStorage.orderId,
      promo_code:datatherapy.coupon,
      current_product_amount:datatherapy.product_amount,
      current_discount:datatherapy.discount,
      current_discount_amount:datatherapy.discount_amount,
      employee_n_key:datatherapy.doc_app_id,  
    };
    console.log(data);
    const resp = await postapi('/followup_payment/',data)
    console.log(resp)
    const res = await postapi('/add_sessionpay/',data)
        console.log(res);
        if (res.data.status == "success") {
          let jsonData = {
            patient_n_key: localStorage._patientKey,
            app_prod_grp_key: datatherapy.app_prod_grp_key,
          };
          const reponse = await postapi('/previous_check/',jsonData)
              console.log("cancel appointment", res.data);
              if (reponse.status == "success") {
                if (reponse.data[0].is_active == 1) {
                  this.setState({ _fol_therapist: false });
                  let appointmentDetail = JSON.stringify({
                    doc_app_id: reponse.data[0].employee_n_key,
                    app_prod_grp_key: datatherapy.app_prod_grp_key,
                    app_payment_n_key: datatherapy.app_payment_n_key,
                  });
                  localStorage.setItem("therapy_details", appointmentDetail);
                  this.props.history.push("therapyjourney/selectSession/", {
                    action: "Date",
                  });
                } else {
                  this.props?.history?.push('creditsleft',{
                    selectP: datatherapy,
                    creditModal: true,
                    empDetails: reponse.data,
                    _fol_therapist: true,
                  });
                }
              }
        }

  };
  render() {
    const { paymentStatus, creditModal } = this.state;
    return (
      <>
        <Modal centered lg show={creditModal}>
          <ModalBody style={{ textAlign: "center" }}>
            {this.state.empDetails[0] &&
            this.state.empDetails[0].is_active == 0 ? (
              <div className="prec_check_ text_p_ font-weight-bold pt-5">
                Hey, we recently had to let our therapist{" "}
                {`${
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].first_name) +
                  " " +
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].last_name)
                }`}{" "}
                go. If you would like to continue with her/him, send an email{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].email_id}
                </span>{" "}
                or call{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].phone_number}{" "}
                </span>
                <br />
                <div>
                  <br />
                  <p className="text_p_">
                    You're not alone and we're here for you. We have therapists
                    who can help you rebuild your life, learn and grow from the
                    experience, and get back to where you were before this
                    happened. Please reach out if you are ready to begin.
                  </p>
                </div>
                <br />
                <span style={{ fontSize: 12 }}>
                  Our support number: +91 7829-002-002
                </span>
                <div
                  style={{ width: "70%" }}
                  onClick={() => this.setState({ old: false, new: true })}
                  className="prec_check mt-4 border rounded text-center mx-auto py-3"
                >
                  {this.state.new ? (
                    <BsCheck2Circle
                      size={25}
                      style={{ marginRight: "4px" }}
                      className="icn_span"
                    />
                  ) : (
                    <BsCircle
                      style={{ marginRight: "4px" }}
                      size={15}
                      className="icn_span"
                    />
                  )}{" "}
                  I would like to switch therapist
                </div>
              </div>
            ) : (
              <div
                onClick={() => this.setState({ old: true, new: false })}
                className="prec_check mt-4 border rounded"
              >
                {this.state.old ? (
                  <BsCheck2Circle size={30} className="icn_span" />
                ) : (
                  <BsCircle size={15} className="icn_span" />
                )}{" "}
                I would like to continue with my previous therapist
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.setState({ creditModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={this.state.old || this.state.new ? false : true}
              onClick={() => {
                this.setState({ creditModal: false });
                if (this.state.old) {
                  console.log("d");
                }
                if (this.state.new) {
                  if (this.state._fol_therapist) {
                    Store.dispatch(
                      ScheduleAction({
                        data1: this.state.selectP,
                      })
                    );
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                      _fol_therapist: true,
                    });
                  } else {
                    if (
                      this.state.selectP.title === "Psychiatric Consultation"
                    ) {
                      let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
                      Store.dispatch(
                        ScheduleAction({
                          scheduleStep: 1,
                          scheduleCurrentStep: 1,
                          problem: selectedProblem,
                          mode: "Video",
                          data1: this.state.selectP,
                        })
                      );
                    } else {
                      Store.dispatch(
                        ScheduleAction({
                          data1: this.state.selectP,
                        })
                      );
                    }
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                    });
                  }
                }
              }}
            >
              Continue
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <div className="d-flex col-md-12 mx-auto">
          {paymentStatus?.order_status === "PAID" ? (
            <Col md={4} xs={12} className="mt-5 mx-auto">
              <Card>
                <CardSubtitle
                  tag="h3"
                  className="mt-2 pt-2 text-center text-muted"
                >
                  Transaction Status
                </CardSubtitle>
                <CardBody className="col-md-8 mx-auto text-center">
                  <img src={success} style={{width:'140px',height:'140px'}} className="mx-auto success" />
                  <h6>Your appointment is Successfull</h6>
                  <button onClick={()=>window.location.href='/home'} style={{backgroundColor:'#215352',color:'#fff',}} className="font-weight-bold rounded px-3 py-1">
                    Back To Home
                  </button>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col md={4} xs={12} className="mt-5 mx-auto">
              <Card>
                <CardSubtitle
                  tag="h3"
                  className="mt-2 pt-2 text-center text-muted"
                >
                  Transaction Status
                </CardSubtitle>
                <CardBody className="col-md-8 mx-auto text-center">
                  <img src={failed} style={{width:'140px',height:'140px'}} className="mx-auto success" />
                  <h5>Your Transaction Failed</h5>
                  <button onClick={()=>window.location.href='/home'} style={{backgroundColor:'#215352',color:'#fff',}} className="font-weight-bold rounded px-3 py-1">
                    Back To Home
                  </button>
                </CardBody>
              </Card>
            </Col>
          )}
        </div>{" "}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
    getCareData: state.GetCareData,
  };
};

export default withRouter(connect(mapStateToProps, null)(PaymentStatus));
