import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowMoreText from "react-show-more-text";
import { CDN_URL, REACT_APP_API_URL } from "../../processENV";
import { useEffect } from "react";
import { useState } from "react";
import { saveAs } from "file-saver";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BsCheck2, BsCircleFill } from "react-icons/bs";
import { withRouter } from "react-router-dom";

const Goals = (props) => {
  const [documentList, setDocumentList] = useState([]);
  const [goalItem, setGoalItem] = useState({});
  const [count, setCount] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [item, setItem] = useState(true);
  const [Timebound, setTimebound] = useState([
    { name: "A Week", selct: false },
    { name: "A Month", selct: false },
    { name: "3 Months", selct: false },
    { name: "6 Months", selct: false },
    { name: "12 Months", selct: false },
    { name: "Not sure", selct: false },
  ]);
  const [realistic, setRealistic] = useState([
    "Strongly Disagree",
    "Slightly",
    "Neutral",
    "Slightly Agree",
    "Agree",
    "Strongly Agree",
  ]);
  const [createGoal, setCreateGoal] = useState({
    achieve: "",
    appointment_n_key: "",
    goal: "",
    help_to_reach: "",
    hospital_n_key: "",
    life_after_goal: "",
    making_progress: "",
    org_n_key: "",
    patient_n_key: "",
    possible: "",
    prioritize: "",
    realistic: "",
    status: "",
    time_bound: "",
    time_bound_range: "",
    what_get_inthis: "",
    why_this: "",
  });
  const [emptygoal, setEmptygoal] = useState({
    achieve: "",
    appointment_n_key: "",
    goal: "",
    help_to_reach: "",
    hospital_n_key: "",
    life_after_goal: "",
    making_progress: "",
    org_n_key: "",
    patient_n_key: "",
    possible: "",
    prioritize: "",
    realistic: "",
    status: "",
    time_bound: "",
    time_bound_range: "",
    what_get_inthis: "",
    why_this: "",
  });
  const [add, setAdd] = useState(false);
  const [url, setUrl] = useState([
    `${CDN_URL}/APP/05.png`,
    `${CDN_URL}/APP/06.png`,
   `${CDN_URL}/APP/07.png`,
   `${CDN_URL}/APP/08.png`,
  ]);
  const [urlss, setUrlss] = useState([
   `${CDN_URL}/APP/09.png`,
   `${CDN_URL}/APP/10.png`,
   `${CDN_URL}/APP/11.png`,
    `${CDN_URL}/APP/12.png`,
  ]);

  const downld = async (document_attachment, document_name) => {
    saveAs(document_attachment, document_name);
  };
  const getDocumentList = async () => {
    let data = { patient_n_key: localStorage.getItem("_patientKey") };
    fetch(`${REACT_APP_API_URL}/list_goals/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("document list", res);
        setDocumentList(res.data);
      })
      .catch((err) => console.log("exercise list error", err));
  };
  useEffect(() => {
    getDocumentList();
    getChat();
  }, []);
  const handleIc = () => {
    setGoalItem("");
    setShow(!show);
  };

  const getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/app_chathome/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          setItem(res);
        } else {
          console.log("meeting id", res.data);
        }
      });
  };

  const joinFreeChat = async () => {
    const pt_key = localStorage._patientKey;
    const JsonData = {
      patient_n_key: localStorage._patientKey,
    };
    fetch(REACT_APP_API_URL + "/counselling_room/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === "success") {
          let JsonData1 = {
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(JsonData1),
          })
            .then((response) => response.json())
            .then(async (resp) => {
              if (resp.status == "success") {
                localStorage.uid = pt_key;
                localStorage.channelName = res.channelid;
                props.history.push("/chatscreen", {
                  channelId: res.channelid,
                  token: resp.rtm_data,
                  uid: pt_key,
                  typeofchat: "CounsellingRoom",
                  freeChat: res,
                  firstChat: res.new_user,
                  doctor_photo:
                    item &&
                    item?.support_chat &&
                    item?.support_chat.length !== 0
                      ? item?.support_chat[0]?.doctor_photo
                      : null,
                  doctor_name:
                    item &&
                    item?.support_chat &&
                    item?.support_chat.length !== 0
                      ? item.support_chat[0]?.doctor_name
                      : null,
                  disable:
                    item &&
                    item?.support_chat &&
                    item?.support_chat.length !== 0
                      ? item.support_chat[0]?.expired
                      : false,
                });
              }
            });
        }
      });
  };

  const ViewGoal = () => {
    return (
      goalItem && (
        <div className="col-md-10 text-center mt-5 mx-auto border rounded shadow-sm pb-md-4">
          <div className="mt-5">
            <div className="text-left">
              <button
                onClick={() => handleIc()}
                className="btn btn-light shadow"
              >
                <ArrowLeft color="#215352" /> back
              </button>
            </div>
            <div className="text-right col">
              <p>Motivation to achieve the goal</p>
              <div className="row d-flex justify-content-end">
                {count.map((i, ind) => {
                  return (
                    <p
                      key={ind}
                      className={`p-2 goal_btn ${
                        i == goalItem.achieve ? "shd_goal" : ""
                      }`}
                      style={{
                        backgroundColor:
                          i == goalItem.achieve
                            ? "#fff"
                            : ind == 0
                            ? "#DFE257"
                            : ind == 1
                            ? "#ECEA0E"
                            : ind == 2
                            ? "#FAF701"
                            : ind == 3
                            ? "#88E16A"
                            : ind == 4
                            ? "#5FC713"
                            : ind == 5
                            ? "#32A404"
                            : ind == 6
                            ? "#147325"
                            : ind == 7
                            ? "#E98838"
                            : ind == 8
                            ? "#D13434"
                            : ind == 9
                            ? "#FF0200"
                            : "#000",
                        color: i == goalItem.achieve ? "#000" : "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {i}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-center">
            <img
              className="img-fluid"
              src={`${CDN_URL}/newapp/acheive.png`}
              style={{ maxWidth: "300px" }}
            />
          </div>
          <div className="col text-left mt-4">
            <h5
              style={{ opacity: 0.8 }}
              className="m-3 font-weight-bold text-left header_font_ _text_color"
            >
              {goalItem.goal}
            </h5>
            <div className="pl-md-1">
              <div className="col ">
                <p className="mt-3 header_font_ h5 pb-3 _text_color">
                  why did you choose this goal?
                </p>
                <ShowMoreText
                  lines={2}
                  more="Show more"
                  less="Show less"
                  expanded={false}
                  className="mt-n3"
                  truncatedEndingComponent={"... "}
                >
                  <p className="text_p_" style={{ marginTop: "-10px" }}>
                    {goalItem.why_this}
                  </p>
                </ShowMoreText>
              </div>
              <div className="col">
                <p className="mt-3  header_font_ h5 pb-3 _text_color">
                  what i get in this goal?
                </p>
                <ShowMoreText
                  lines={2}
                  more="Show more"
                  less="Show less"
                  expanded={false}
                  className="mt-n3"
                  truncatedEndingComponent={"... "}
                >
                  <p style={{ marginTop: "-10px" }}>
                    {goalItem.what_get_inthis}
                  </p>
                </ShowMoreText>
              </div>
              <div className="col ">
                <p className="mt-3  header_font_ h5 pb-3 _text_color">
                  Help for reach
                </p>
                {goalItem.help_to_reach ? (
                  <ShowMoreText
                    lines={2}
                    more="Show more"
                    less="Show less"
                    expanded={false}
                    className="mt-n3"
                    truncatedEndingComponent={"... "}
                  >
                    <p style={{ marginTop: "-10px" }}>
                      {goalItem.help_to_reach}
                    </p>
                  </ShowMoreText>
                ) : (
                  <p className="mt-n3">
                    Your goal is not easy, but we appreciate your effort. Reach
                    for the stars with a flexible approach by working with a{" "}
                    <span
                      onClick={() => {
                        window.location.href = "/offerings";
                      }}
                      style={{
                        fontWeight: "800",
                        textDecorationLine: "underline",
                        color: "#215352",
                      }}
                    >
                      professional therapist
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={() => joinFreeChat()}
                      style={{
                        fontWeight: "800",
                        textDecorationLine: "underline",
                        color: "#215352",
                      }}
                    >
                      coach
                    </span>{" "}
                    who can help you reach your goals. Click to connect with us!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };
  const validateVal = (values) => {
    const createGoal = values;
    console.log(createGoal);
    if (
      createGoal.goal.length > 2 &&
      createGoal.why_this.length > 2 &&
      createGoal.what_get_inthis.length > 2 &&
      ((createGoal.time_bound && createGoal.time_bound == "Long Term Goal") ||
        (createGoal.time_bound == "Short Term Goal" &&
          createGoal.time_bound_range)) &&
      createGoal.life_after_goal.length > 2 &&
      createGoal.achieve
    ) {
      setDisable(false);
    } else setDisable(true);
  };
  useEffect(() => {
    validateVal(createGoal);
  }, [createGoal]);
  const handleGoal = (id, val) => {
    setCreateGoal({ ...createGoal, [id]: val });
  };
  const handleCreate = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      achieve: createGoal.achieve,
      appointment_n_key: "",
      goal: createGoal.goal,
      help_to_reach: createGoal.help_to_reach,
      hospital_n_key: "",
      life_after_goal: createGoal.life_after_goal,
      making_progress: createGoal.making_progress,
      org_n_key: "",
      possible: createGoal.possible,
      prioritize: createGoal.prioritize,
      realistic: createGoal.realistic,
      status: "",
      time_bound: createGoal.time_bound,
      time_bound_range: createGoal.time_bound_range
        ? createGoal.time_bound_range
        : "",
      what_get_inthis: createGoal.what_get_inthis,
      why_this: createGoal.why_this,
    };
    fetch(`${REACT_APP_API_URL}/add_goals/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("document list", res);
        if (res.status == "success") {
          getDocumentList();
          setCreateGoal(emptygoal);
          setAdd(false);
        }
      })
      .catch((err) => console.log("exercise list error", err));
  };
  const handleClick = async (i) => {
    setGoalItem(i);
    setShow(!show);
  };
  const closeBtn = (
    <button
      className="close"
      style={{ position: "absolute", right: 10, top: 2 }}
      onClick={() => {
        setAdd(false);
        setCreateGoal(emptygoal);
      }}
      type="button"
    >
      &times;
    </button>
  );

  return (
    <div className="container">
      <div className="col-md-8 mx-auto mt-5  pt-5">
        <ToastContainer />
        <h3
          style={{ textDecorationLine: "underline" }}
          className="header_font_ _text_color text-center"
        >
          Goal-Setting Dashboard
        </h3>
        {!show && (
          <div className="text-right mt-2 pt-4 mx-auto col-md-8">
            <button
              onClick={() => setAdd(true)}
              className="mr-5 mr-md-0 ml-md-5 ml-n4 btn"
              style={{ background: "#215352", color: "#fff" }}
            >
              Add goal (+)
            </button>
          </div>
        )}
        <Modal scrollable centered isOpen={add}>
          <ModalHeader
            close={closeBtn}
            style={{ textAlign: "center", margin: "10px auto" }}
          >
            <h4>Create your goal</h4>
          </ModalHeader>
          <ModalBody style={{ overflow: "scroll" }}>
            <div className="col mt-3">
              <label className="text-left">
                1. Write the goal you have in mind?
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={createGoal.goal}
                placeholder="Type your answer here (minimum 3 characters)"
                onChange={(e) => handleGoal("goal", e.target.value)}
              />
            </div>
            <div className="col mt-3">
              <label className="text-left">
                2. Reason for choosing the goal?
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={createGoal.why_this}
                placeholder="Type your answer here (minimum 3 characters)"
                onChange={(e) => handleGoal("why_this", e.target.value)}
              />
            </div>
            <div className="col mt-3">
              <label className="text-left">
                3. What might get in the way of this goal?
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={createGoal.what_get_inthis}
                placeholder="Type your answer here (minimum 3 characters)"
                onChange={(e) => handleGoal("what_get_inthis", e.target.value)}
              />
            </div>
            <div className="col mt-3">
              <label className="text-left">4.Prioritize the goal</label>
              <div className="row ml-3">
                <button
                  className={`ml-1 ${
                    createGoal.prioritize == "High"
                      ? "btn btn-success"
                      : "btn btn-info"
                  }`}
                  onClick={() => {
                    handleGoal("prioritize", "High");
                  }}
                >
                  {createGoal.prioritize == "High" ? (
                    <BsCheck2 size={16} />
                  ) : (
                    <BsCircleFill />
                  )}{" "}
                  High
                </button>
                <button
                  className={`ml-3 ${
                    createGoal.prioritize == "Medium"
                      ? "btn btn-success"
                      : "btn btn-info"
                  }`}
                  onClick={() => {
                    handleGoal("prioritize", "Medium");
                  }}
                >
                  {createGoal.prioritize == "Medium" ? (
                    <BsCheck2 size={16} />
                  ) : (
                    <BsCircleFill />
                  )}{" "}
                  Medium
                </button>
              </div>
            </div>
            <div className="col mt-3">
              <label className="text-left">
                5.What(or Who) will help you reach this goal?
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={createGoal.help_to_reach}
                placeholder="Type your answer here (minimum 3 characters)"
                onChange={(e) => handleGoal("help_to_reach", e.target.value)}
              />
            </div>
            <div className="col mt-3">
              <label className="text-left">
                6.When will this goal be achieved by?
              </label>
              <div className="row ml-3">
                <button
                  className={`ml-1 ${
                    createGoal.time_bound == "Short Term Goal"
                      ? "btn btn-success"
                      : "btn btn-info"
                  }`}
                  onClick={() => handleGoal("time_bound", "Short Term Goal")}
                >
                  {createGoal.time_bound == "Short Term Goal" ? (
                    <BsCheck2 size={16} />
                  ) : (
                    <BsCircleFill />
                  )}{" "}
                  Short Term Goal
                </button>
                <button
                  className={`ml-3 ${
                    createGoal.time_bound == "Long Term Goal"
                      ? "btn btn-success"
                      : "btn btn-info"
                  }`}
                  onClick={() => handleGoal("time_bound", "Long Term Goal")}
                >
                  {createGoal.time_bound == "Long Term Goal" ? (
                    <BsCheck2 size={16} />
                  ) : (
                    <BsCircleFill />
                  )}{" "}
                  Long Term Goal
                </button>
              </div>
              {createGoal.time_bound == "Short Term Goal" ? (
                <>
                  <div className="mt-3 row d-flex m-2">
                    {Timebound.map((i, indx) => {
                      return (
                        <button
                          className={`ml-2 mt-2 ${
                            createGoal.time_bound_range == i.name
                              ? "btn btn-success"
                              : "btn btn-info"
                          }`}
                          key={indx}
                          onClick={() => handleGoal("time_bound_range", i.name)}
                        >
                          {createGoal.time_bound_range == i.name ? (
                            <BsCheck2 size={16} />
                          ) : (
                            <BsCircleFill />
                          )}{" "}
                          {i.name}
                        </button>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
            <div className="col mt-3">
              <label className="text-left">
                7. What might your life look like if you were able to reach this
                goal?
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={createGoal.life_after_goal}
                placeholder="Type your answer here (minimum 3 characters)"
                onChange={(e) => handleGoal("life_after_goal", e.target.value)}
              />
            </div>

            <div className="col mt-3">
              <label className="text-left">
                8. How motivated are you to achieve this goal?
              </label>
              <div className="scale">
                {count.map((i, ind) => {
                  return (
                    <button
                      onClick={() => handleGoal("achieve", i)}
                      className="range"
                      style={{
                        padding: "10px",
                        height: "40px",
                        backgroundColor:
                          createGoal.achieve === i
                            ? "#fff"
                            : ind == 0
                            ? "#DFE257"
                            : ind == 1
                            ? "#ECEA0E"
                            : ind == 2
                            ? "#FAF701"
                            : ind == 3
                            ? "#88E16A"
                            : ind == 4
                            ? "#5FC713"
                            : ind == 5
                            ? "#32A404"
                            : ind == 6
                            ? "#147325"
                            : ind == 7
                            ? "#E98838"
                            : ind == 8
                            ? "#D13434"
                            : ind == 9
                            ? "#FF0200"
                            : "#000",
                      }}
                    >
                      <p
                        style={{
                          color: createGoal.achieve === i ? "#000" : "#fff",
                          alignSelf: "center",
                          marginTop: "-3px",
                          fontWeight: "bold",
                        }}
                      >
                        {i}
                      </p>
                    </button>
                  );
                })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ background: disable ? "grey" : "#215352" }}
              className={`btn btn-lg btn-block`}
              disabled={disable}
              onClick={() => handleCreate()}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>
        {show && goalItem && <ViewGoal />}
        {!show && documentList.length !== 0 ? (
          <div className="mt-5">
            {documentList.map((i, indx) => {
              return (
                <div
                  onClick={() => handleClick(i)}
                  className="row shadow-sm col-md-7  mt-3  d-flex  mx-auto flex-row  position-relative  rounded"
                  style={{ border: "1px solid #215352" }}
                >
                  <div className=" p-2 row">
                    <img
                      src={
                        indx % 2 == 0
                          ? url[Math.floor(Math.random() * url.length)]
                          : urlss[Math.floor(Math.random() * urlss.length)]
                      }
                      style={{ width: "90px", height: "90px" }}
                    />
                  </div>
                  <span
                    style={{
                      right: 0,
                      top: 0,
                      color: "#fff",
                      background: "#0394fc",
                      fontSize: 10,
                    }}
                    className="position-absolute p-1"
                  >
                    Check-In
                  </span>
                  <div className="col p-3">
                    <ShowMoreText
                      className="expan_sec"
                      lines={2}
                      more="Show more"
                      less="Show less"
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      <span className="title_goal">{i.goal}</span>
                      <br />
                    </ShowMoreText>
                    <span style={{ fontWeight: "400" }}>
                      Priority : {i.prioritize}; Time Bound :{" "}
                      {i.time_bound_range ? i.time_bound_range : "Long term"}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          !show && (
            <div
              className="row col-md-6 mx-auto my-auto mt-md-5"
              // onClick={() => window.location.replace("offerings")}
            >
              <div className="mt-md-5">
                <h4 className="text-center font-weight-bold h3">
                  We Can’t wait to meet you!
                </h4>
                <p className="text-center  mt-4 h5">
                  Schedule your 1st appointment to get connected to your CareMe
                  Therapist.
                </p>
                <p className="text-center mt-5 h5">
                  <span className="h5">Need help getting started?</span>
                  <br />
                  Reach out to <a href="">care@careme.health</a> and a team
                  member will respond as soon as possible.
                </p>
              </div>
              <div className="text-center mx-auto mt-4">
                <button
                  style={{
                    background: "#215352",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  className="btn btn-md btn-block "
                  onClick={() => window.location.replace("offerings")}
                >
                  Book your first session <ArrowRight />{" "}
                </button>
              </div>
            </div>
          )
        )}
        {!show && (
          <div className="text-center mt-md-4  mt-3">
            <img
              className="img-fluid mt-md-5 mt-3"
              src={`${CDN_URL}/newapp/goal%20copy.png`}
              style={{ maxWidth: "400px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default withRouter(Goals);
