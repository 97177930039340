import React from "react";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { ArrowLeftCircle, Flag } from "react-feather";
import Store from "../../../../Store";
import { ScheduleAction } from "../../../../Actions/ScheduleAction";
import Loader from "../../Loader";
import { REACT_APP_API_URL } from "../../../processENV";

class TimeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      time: [],
    };
  }

  componentDidMount() {
    this.getMode();
    console.log(this.props);
  }

  getMode = () => {
    const scheduleData = this.props?.scheduleData?.data;
    const item = this.props?.item;
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      app_prod_n_key: scheduleData?.problem.app_prod_n_key,
      mode_of_telehealth: scheduleData?.mode,
      rescheduled_date: scheduleData?.date,
      preferred_language: scheduleData?.language,
      app_prod_grp_key: item?.app_prod_grp_key,
      therapist_gender: scheduleData?.therapist?.gender,
      employee_n_key: scheduleData?.therapist?.employee_n_key,
      
    };
    fetch(`${REACT_APP_API_URL}/app_rescheduled_time/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("selected service", res.data);
        console.log(data);
        if (res.status === "success") {
          this.setState({ time: res.data });
        } else {
          console.log("selected service", res.data);
        }
      });
  };

  selecttime = (value) => {
    const item = this.props?.item;
    const scheduleData = this.props?.scheduleData?.data;
    Store.dispatch(
      ScheduleAction({
        time: value,
        mode: scheduleData?.mode,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        gender: scheduleData?.gender,
        date: scheduleData?.date,
        therapist: scheduleData?.therapist,
        pt_key:scheduleData?.pt_key,
        scheduleStep:
          item?.title === "Couple Therapy"
            ? 5
            : item?.title === "Psychiatric Consultation"
            ? 4
            : 6,
        scheduleCurrentStep:
          item?.title === "Couple Therapy"
            ? 5
            : item?.title === "Psychiatric Consultation"
            ? 4
            : 6,
      })
    );
  };

  goBack = () => {
    const item = this.props?.item;
    const scheduleData = this.props?.scheduleData?.data;
    Store.dispatch(
      ScheduleAction({
        scheduleStep:
          item?.title === "Couple Therapy"
            ? 3
            : item?.title === "Psychiatric Consultation"
            ? 2
            : 4,
        scheduleCurrentStep:
          item?.title === "Couple Therapy"
            ? 3
            : item?.title === "Psychiatric Consultation"
            ? 2
            : 4,
        mode: scheduleData?.mode,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        gender: scheduleData?.gender,
        date: scheduleData?.date,
        therapist: scheduleData?.therapist,
        pt_key:scheduleData?.pt_key,
      })
    );
  };

  render() {
    const { time, loading } = this.state;
    return (
      <div className="col-md-8 mx-auto px-0  pt-5">
             <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none">
          <ArrowLeftCircle color="#215352" size={25} />
        </button>
        {loading && <Loader />}
        <div className="d-flex mx-auto mt-1 px-0">
          <Col className="mx-auto text-center">
            <p
              style={{ fontSize: "26px" }}
              className="h4 text-left text-md-center _text_color header_font_ font-weight-bold"
            >
              Select Time
            </p>
            <p
              style={{ fontSize: "16px" }}
              className="h4 text-left pt-2 text-md-center _text_color header_font_ font-weight-bold"
            >
              You can schedule your appointments now based on your convenience
            </p>
            <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">
              {time.length > 0 &&
                time.map((item, i) => {
                  return (
                    <button
                    style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                    className={"p-3 rounded bg-white _text_color text_p_ shadow-sm text_medium_ my-2"}
                    key={item}
                      onClick={() => this.selecttime(item)}
                    >
                      {item}
                    </button>
                  );
                })}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
    scheduleData: state.ScheduleData,
  };
};

export default connect(mapStateToProps, null)(TimeSelect);
