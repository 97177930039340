import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import LeftContent from "./LeftContent";
import MobileNumber from "./MobileNumber";
import OtpValidation from "./OtpValidation";
import Store from "../../../Store";
import { LoginStateAction } from "../../../Actions/LoginAction";
import RegistrationForm from "./RegistrationForm";

class NewLogin extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      step: 0,
      mobileNumber: "",
      countryCode: "",
      business: false,
    };
  }ß

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginData !== this.props.loginData) {
      this.setState({
        step: this.props.loginData.data?.step,
        mobileNumber: this.props.loginData.data?.mobileNumber,
        countryCode: this.props.loginData.data?.countryCode,
      });
    }
  }

  componentDidMount() {
    if (this.componentRef.current) {
      this.componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    console.log(this.props, window.location.pathname);
    if (this.props.loginData?.data?.fromreg_) {
      //   this.navigateComponent();
    }
    Store.dispatch(
      LoginStateAction({
        step: this.props.loginData?.data?.step,
        currentStep:
          this.props.loginData?.data?.currentStep ||
          localStorage.getItem("_currentStep") !== null ||
          localStorage.getItem("_currentStep") !== undefined
            ? localStorage.getItem("_currentStep")
            : "",
        mobileNumber: this.props.loginData.data.mobileNumber,
        countryCode: this.props.loginData.data.countryCode,
        otp: this.props.loginData.data.otp,
        language: this.props.loginData.data.language,
        detail: this.props.loginData.data.detail,
        business: this.props.location.state.business == true ? true : false,
        email: this.props?.loginData?.data?.email || null,
      })
    );
  }
  navigateComponent = (id, business) => {
    this.setState({
      step: id || this.props.loginData?.data?.step || "",
      business: business ? true : false,
    });
    Store.dispatch(
      LoginStateAction({
        step: id || this.props.loginData?.data?.step,
        currentStep:
          this.props.loginData?.data?.currentStep ||
          localStorage.getItem("_currentStep") !== null ||
          localStorage.getItem("_currentStep") !== undefined
            ? localStorage.getItem("_currentStep")
            : "",
        mobileNumber: this.props.loginData.data.mobileNumber,
        countryCode: this.props.loginData.data.countryCode,
        otp: this.props.loginData.data.otp,
        language: this.props.loginData.data.language,
        detail: this.props.loginData.data.detail,
        business:
          this.props.loginData?.data?.business || business ? true : false,
        email: this.props?.loginData?.data?.email || null,
      })
    );
  };

  render() {
    const { step, business } = this.state;
    return (
      <>
        <div ref={this.componentRef} className={"login-main"}>
          <Row className="m-0">
            <Col
              md={8}
              className="col-sm-push-12 order-lg-12  px-0 px-md-auto pr-0"
            >
              {step === 0 && <MobileNumber />}
              {step === 1 && <OtpValidation />}
              {step === 2 && <RegistrationForm />}
            </Col>
            <Col
              md={4}
              className={
                step === 2
                  ? "col-sm-push-1 order-lg-1   px-0 register-left"
                  : "col-sm-push-1 order-lg-1   px-0"
              }
            >
              <LeftContent
                currentStep={this.props.loginData.data?.currentStep}
                step={step}
                navigateComponent={this.navigateComponent}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
  };
};

export default connect(mapStateToProps, null)(NewLogin);
