import logo from './logo.svg';
import React, { Component, Suspense, useEffect, useState } from "react";

import './assets/scss/main.scss'
import './App.css';
import "./i18n";

// import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/Layouts/Login/Login';
import SessionTimeout from './utils/SessionTimeout';

const App =()=>{
  
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(true);
    };

    const handleOfflineStatus = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, []);


  return (
    <div className="App">
      <Login/>
      {/* <SessionTimeout /> */}
    </div>
  );
}

export default App;
