import React from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Store from "../../../Store";
import { ScheduleAction } from "../../../Actions/ScheduleAction";

const TherapistLeft = (props) => {
  const dataDetails =props?.location?.state?.empDetails[0];
  const therapistName = dataDetails?.first_name + " " + dataDetails?.last_name;
  const item = props?.location?.state?.data;
  item.switch=true

  const getStep=(title)=>{
    switch (title) {
        case "Couple Therapy":
          return 1;
        case "Psychiatric Consultation":
          return 0;
        default:
          return 1;
      }
   }
  const getApprodKey=(title)=>{
     switch (title) {
       case "Couple Therapy":
         return { app_prod_n_key: "APP_PROD-5" };
       case "Psychiatric Consultation":
         return { app_prod_n_key: "APP_PROD-3" };
       default:
         return {app_prod_n_key:item.app_prod_n_key};
     }
   }
  return (
    <Container>
      <div className="col-md-8 mx-auto pt-md-5 mt-md-5 pt-3">
        <p
          className="header_font_ text-center"
          style={{
            fontSize: 30,
            fontWeight: "600",
            color: "#215352",
            textDecorationLine: "underline",
          }}
        >
          Your Care Partner Has Changed
        </p>
        <p
          className="text_p_"
          style={{ fontSize: 20, color: "#615e5e", paddingVertical: 18 }}
        >
          We want you to know that your current therapist {therapistName || ""}{" "}
          is no longer with CareMe Health. Your well-being is our top priority,
          and we're here to support you through this transition.
        </p>
        <p
          className="text_p_"
          style={{ fontSize: 20, color: "#615e5e", paddingVertical: 18 }}
        >
          If you're ready, we can help you find a new care partner who
          understands your journey. We're here for you, every step of the way.
        </p>

        <div className="mx-auto text-center mt-5">
          <button
            className="btn rounded header_font_ px-5"
            style={{
              fontSize: "20px",
              borderColor: "#215352",
              color: "#215352",
              backgroundColor: "#ddefe5",
            }}
            onClick={() =>{
                Store.dispatch(
                    ScheduleAction({
                      scheduleStep:getStep(item?.title),
                      scheduleCurrentStep:getStep(item?.title),
                      problem: getApprodKey(item?.title),
                      mode: "Video",
                      data1: item,
                    })
                  );
                props?.history?.push("/schedule/appointment", { data1: item });   }}
          >
            Switch Therapist
          </button>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(TherapistLeft);
