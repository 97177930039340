import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { APP_URL, REACT_APP_API_URL } from "../../processENV";
import Loader from "../Loader";
import { postapi } from "../../../Helpers/Api";

const AssessmentAuth = (props) => {
  useEffect(() => {
    const url = window.location.pathname;
    let data = { patient_n_key: "ORG-PAT-" + url.split("/")[1] };
    let id = url.split("/")[3];
    checkUser(data,id)
  }, []);

 const checkUser=async(key,id)=>{
    const res = await postapi('/patientedit_app/',key)
        if (res.status === "success") {
          localStorage.setItem("status", "completed");
          localStorage.setItem("_patientKey", key.patient_n_key);
          const data={
            patient_n_key:key.patient_n_key,
            order_form_id:id
         }
          const resp = await postapi('/view_therapy_result/',data)
          if(resp.status==='success'){
            props.history.push('/assesment-result-view',{data:{data:resp.data}})
          }
          else props.history.push('/workbook')
        } else {
          props.history.push('/home')
        }
  }

  return (
    <div style={{ margin: "150px auto" }}>
      <Loader />
    </div>
  );
};

export default AssessmentAuth;
